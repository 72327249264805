const Configuration = {
  baseService: {
    baseUrl: 'http://localhost:8080',
  },
  oauth: {
    clientId: '4bfd9ea4-9d64-11ed-a8fc-0242ac120002',
    clientSecret: 'fj8aymwkctwu8duazucu7dhf',
  },
  googleMaps: {
    apiKey: 'https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyDn7xyjUQProNe9euZgNZOmLZUDkU0n2cU',
  },
  supportEmail: {
    email: 'info@info.com',
  },
  firebase: {
    /* Overrided by web/config/config.local.ts */
    config: {},
    notificationUrl: {},
  },
  firebaseVapidKey: 'BIoMXpPlTtBcOwnsOR1oUrwTLGJDaM2ogK_OZiFCU8FtVd41futSXOtSmXWaB5RgMQKNvZz3NNeRGJd_nWKQjeI',
};

// Export configuration
export default Configuration;
