import {UsersServiceTypes as UsersServiceTypesRC} from '@bri/rotimpres-core';
import {useBaseService} from '@bri/shared-components';
import {ServiceFunctionsType} from '@bri/shared-core';

export type UsersServiceTypes = Pick<UsersServiceTypesRC, 'create'> &
  Pick<UsersServiceTypesRC, 'update'> &
  Pick<UsersServiceTypesRC, 'register'> &
  Pick<UsersServiceTypesRC, 'removeSelf'> &
  Pick<UsersServiceTypesRC, 'updateNotifications'> &
  Pick<UsersServiceTypesRC, 'newNotifications'>;

export function useUsersService() {
  const newBaseService = useBaseService();

  const usersServiceFunctions: ServiceFunctionsType<UsersServiceTypes> = {
    create: params => newBaseService().auth().post('v1/users/create', params),
    update: params => newBaseService().auth().put('v1/users/update', params),
    register: params => newBaseService().post('v1/users/register', params),
    removeSelf: params => newBaseService().auth().delete('v1/users/removeSelf', params),
    updateNotifications: params => newBaseService().auth().put('v1/users/updateNotifications', params),
    newNotifications: params => newBaseService().auth().get('v1/users/newNotifications', params),
  };
  return usersServiceFunctions;
}
