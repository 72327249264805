import React, {FC, useEffect, useState} from 'react';
import {Button, FormControl, Icon, Image, Input, ScrollView, Text, VStack} from 'native-base';
import {COOKIES_TYPES, CookiesService, loggedUser, TOAST_STATUS, TOAST_VARIANT, useAuthService, useConfig, useSharedToast} from '@bri/shared-components';
import {useSetRecoilState} from 'recoil';
import {useTranslation} from 'react-i18next';
import * as randomstring from 'randomstring';
import sha256 from 'sha256';
import * as Yup from 'yup';
import {Message} from 'yup';
import {Controller, useForm} from 'react-hook-form';
import {Link} from '@react-navigation/native';
import {yupResolver} from '@hookform/resolvers/yup';
import {AntDesign} from '@native-base/icons';
import {BottomTabScreenProps} from '@react-navigation/bottom-tabs';
import {TAG_RESPONSE} from '@bri/rotimpres-core';
import {ScreensParams} from '../navigators/screens';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import logo from '../images/rot_logo.png';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import logo_footer from '../images/rot_footer_logo.png';
import {changeLanguage} from '../utils/languageUtils';
import usePushToken, {TOKEN_UPDATE_MODE} from '../utils/firebase/usePushToken';

type Props = BottomTabScreenProps<ScreensParams, 'Login'>;

export const LoginScreen: FC<Props> = props => {
  const {t} = useTranslation();
  const authService = useAuthService();
  const setUser = useSetRecoilState(loggedUser);
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [config] = useConfig();
  const sharedToast = useSharedToast();
  // const navigate = useNavigate();
  const [inputEmail, setInputEmail] = useState('');
  const [inputPassword, setInputPassword] = useState('');
  const {updatePushToken} = usePushToken();

  useEffect(() => {
    const fetchData = async () => {
      if (await CookiesService.hasKey(COOKIES_TYPES.TECHNICAL, 'access_token')) {
        authService
          .me()
          .apiResponse(() => {
            setLoading(false);
          })
          .response(resp => {
            setUser(resp.user);
          })
          .error(err => console.log(err));
      } else {
        setLoading(false);
      }
    };
    fetchData().catch(console.error);
  }, []);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email(t('Invalid email') as Message),
    // .required(t('Required') as Message),
    password: Yup.string()
      .min(8, t('Too Short') as Message)
      .required(t('Required') as Message),
  });

  const {
    control,
    register,
    setValue,
    reset,
    formState: {errors},
    handleSubmit,
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(LoginSchema),
  });

  if (!config.oauth || !config.oauth.clientId || !config.oauth.clientSecret) {
    return null;
  }

  function tagSharedToast(tagResponse?: TAG_RESPONSE) {
    if (tagResponse !== undefined && tagResponse !== TAG_RESPONSE.MINE) {
      if (tagResponse === TAG_RESPONSE.CLAIM) {
        sharedToast({
          title: t('Tag claimed!'),
          description: t(''),
          status: TOAST_STATUS.SUCCESS,
          variant: TOAST_VARIANT.SUBTLE,
        });
      } else if (tagResponse === TAG_RESPONSE.TRANSFER) {
        sharedToast({
          title: t('Petition successfully submitted'),
          description: t('Wait for a reply'),
          status: TOAST_STATUS.SUCCESS,
          variant: TOAST_VARIANT.SUBTLE,
        });
      } else if (tagResponse === TAG_RESPONSE.TRANSFER_WAIT) {
        sharedToast({
          title: t('Wait'),
          description: t(`Wait a time before making a request again`),
          status: TOAST_STATUS.ERROR,
          variant: TOAST_VARIANT.SUBTLE,
        });
      }
    }
  }

  const submitLogin = (values: any) => {
    if (!config.oauth || !config.oauth.clientId || !config.oauth.clientSecret) {
      console.error('OAuth Module not configured properly.');
      return;
    }
    setIsLoading(true);
    const codeVerifier = randomstring.generate();
    const state = randomstring.generate();
    const codeChallenge = sha256(codeVerifier);
    authService
      .login({
        username: values.email.toLowerCase(),
        password: values.password,
        client_id: config.oauth.clientId,
        client_secret: config.oauth.clientSecret,
        response_type: 'code',
        redirect_uri: 'localhost',
        state,
        scope: 'ROLE:2',
        code_challenge_method: 'S256',
        code_challenge: codeChallenge,
        tagId: props.route.params ? props.route.params.tagId : undefined, // props.route.params.tagId || undefined,
      })
      .apiResponse(apiResponse => {
        if (!apiResponse.response) {
          setIsLoading(false);
        }
      })
      .response(response => {
        if (response.state === state) {
          authService
            .token({
              grant_type: 'authorization_code',
              redirect_uri: 'localhost',
              code: response.code,
              code_verifier: codeVerifier,
            })

            .response(async res => {
              await CookiesService.setType(COOKIES_TYPES.TECHNICAL, 'access_token', res.access_token);
              await CookiesService.setType(COOKIES_TYPES.TECHNICAL, 'refresh_token', res.refresh_token);
              authService
                .me()
                .response(resp => {
                  setUser(resp.user);
                  changeLanguage(resp.user.language);
                  updatePushToken(TOKEN_UPDATE_MODE.ENTER, resp.user);
                  tagSharedToast((response as any).tagResponse);
                })
                .error(err => {
                  setIsLoading(false);
                  sharedToast({
                    title: t('Something Went Wrong'),
                    description: err.type as string,
                    status: TOAST_STATUS.ERROR,
                    variant: TOAST_VARIANT.SUBTLE,
                  });
                });
            })
            .error(err => {
              setIsLoading(false);
              sharedToast({
                title: t('Something Went Wrong'),
                description: err.type as string,
                status: TOAST_STATUS.ERROR,
                variant: TOAST_VARIANT.SUBTLE,
              });
            });
        } else {
          setIsLoading(false);
          sharedToast({
            title: t('Something Went Wrong'),
            description: t('Server Error [OAUTH STATE]'),
            status: TOAST_STATUS.ERROR,
            variant: TOAST_VARIANT.SUBTLE,
          });
        }
      })
      .error(err => {
        setIsLoading(false);
        if (err.code === 307) {
          sharedToast({title: t('Invalid Credentials'), description: t('Incorrect Email or Password')});
        } else {
          sharedToast({
            title: t('Something Went Wrong'),
            description: err.type as string,
            status: TOAST_STATUS.ERROR,
            variant: TOAST_VARIANT.SUBTLE,
          });
        }
      });
  };

  const handleClearInputEmail = () => {
    setInputEmail('');
  };

  const handleClearInputPassword = () => {
    setInputPassword('');
  };

  if (loading) return null;

  return (
    <ScrollView flex={1} contentContainerStyle={{flex: 1}} showsVerticalScrollIndicator={false} bgColor="primary.500" px={10} pb={8}>
      <VStack space={4} flex={1}>
        <VStack alignItems="center">
          <Image source={logo} alt={t('Icon text').toString()} size="lg" width={195} height={195} />
          <Text color="white" textAlign="center" paddingBottom="20px" paddingTop="10px" width="235px" lineHeight="20px" fontWeight={700} fontSize="16px">
            {t('Access your NFC Track account, collect your tokens and enjoy all the promotions and unique experiences!')}
          </Text>
          <FormControl isRequired isInvalid={'email' in errors} key="email">
            <FormControl.Label variant="register">{t('E-mail')}</FormControl.Label>
            <Controller
              name="email"
              control={control}
              render={({field}) => (
                <Input
                  type="text"
                  variant="register"
                  {...field}
                  // color="secondary.500"
                  value={inputEmail}
                  onChangeText={text => setInputEmail(text)}
                  InputRightElement={
                    inputEmail !== null && inputEmail !== '' ? (
                      <Icon
                        as={AntDesign}
                        name="closecircleo"
                        size={5}
                        color="secondary.500"
                        marginRight={2}
                        onPress={() => {
                          handleClearInputEmail();
                        }}
                      />
                    ) : undefined
                  }
                />
              )}
            />
            {'email' in errors && (
              <FormControl.ErrorMessage>
                <Text color="rose.300">{errors.email?.message}</Text>
              </FormControl.ErrorMessage>
            )}
          </FormControl>

          <FormControl isRequired isInvalid={'password' in errors} mt={2} key="password">
            <FormControl.Label variant="register">{t('Password')}</FormControl.Label>
            <Controller
              name="password"
              control={control}
              render={({field}) => (
                <Input
                  type="password"
                  variant="register"
                  {...field}
                  value={inputPassword}
                  onChangeText={text => setInputPassword(text)}
                  InputRightElement={
                    inputPassword !== null && inputPassword !== '' ? (
                      <Icon
                        as={AntDesign}
                        name="closecircleo"
                        size={5}
                        color="secondary.500"
                        marginRight={2}
                        onPress={() => {
                          handleClearInputPassword();
                        }}
                      />
                    ) : undefined
                  }
                />
              )}
            />
            {'password' in errors ? (
              <FormControl.ErrorMessage>
                <Text color="rose.300">{errors.password?.message}</Text>
              </FormControl.ErrorMessage>
            ) : (
              <></>
            )}
          </FormControl>
        </VStack>
        <VStack alignItems="end">
          <Link style={{color: 'white'}} to={{screen: 'RecoverEmailClient'}}>
            {t('Forgot your Password?')}
          </Link>
          <Button variant="register" mt={5} px={4} onPress={handleSubmit(submitLogin)}
isLoading={isLoading} isLoadingText={t('Submitting')!}>
            <Text color="primary.500">{t('Sign In')} </Text>
          </Button>
        </VStack>
        <VStack flex={1} alignItems="center" justifyContent="flex-end" mt={4}>
          <Image source={logo_footer} alt={t('Icon text').toString()} width="115px" height="40px" />
        </VStack>
      </VStack>
    </ScrollView>
  );
};

export default LoginScreen;
