import {Heading, Text} from 'native-base';
import {useTranslation} from 'react-i18next';
import CommonLayout from '../../layouts/CommonLayout';

type Props = {
  company: {
    name: string;
    id: string;
    address: string;
    contactEmail: string;
  };
  projectName: string;
  privacy_policy_last_update: string;
};
const PrivacyPolicyScreen = () => {
  const {t} = useTranslation();

  const props: Props = {
    company: {
      name: 'Impressions Rotatives Offset SA',
      id: 'A17166794',
      address: "Calle pla de L'estany (pol. ind. casa Nova), S/N, Aiguaviva, 17181, Girona",
      contactEmail: 'support@nfctrack.com',
    },
    projectName: 'NFC track',
    privacy_policy_last_update: '15/09/2023',
  };

  return (
    <CommonLayout>
      <>
        <Heading fontSize="3xl" mb={3}>
          {t('Privacy policy')}
        </Heading>

        <Text mb={2}>
          {t(
            'This Privacy Policy is meant to help you understand what information we collect, why we collect it, and how you can update, manage, export, and delete your information. If European Union or United Kingdom data protection law applies to the processing of your information, you can review the European requirements section below to learn more about your rights and  {{companyName}} compliance with these laws.',
            {
              companyName: props.company.name,
            }
          )}
        </Text>
        <Text mb={2}>
          {t(
            'Our Company is part of the Our Company Group which includes Our Company International and Our Company Direct. This privacy policy will explain how our organization uses the personal data we collect from you when you use our website.'
          )}
        </Text>
        <Heading mb={3} mt={3} size="md">
          {t('What data do we collect?')}
        </Heading>
        <Text mb={2}>
          {t(
            'We collect information to provide better services to all our users — from figuring out basic stuff like which language you speak, to more complex things like {{ projectName }} usage. The information {{ projectName }} collects, and how that information is used, depends on how you use our services and how you manage your privacy controls. When you’re not signed in to a {{ projectName }} Account, we store the information we collect with unique identifiers tied to the browser, application, or device you’re using. This allows us to do things like maintain your preferences across browsing sessions, such as your preferred language or whether to show you more relevant search results or ads based on your activity. When you’re signed in, we also collect information that we store with your {{ projectName }} Account, which we treat as personal information.',
            {
              projectName: props.projectName,
            }
          )}
        </Text>
        <Heading mb={3} mt={3} size="md">
          {t('Things you create or provide to us')}
        </Heading>
        <Text mb={2}>
          {t('When you create a {{ projectName }} Account, you provide us with personal information that includes:', {
            projectName: props.projectName,
          })}
        </Text>
        <Text ml={8}>
          {'\u2022 '}
          {t('Name')}
        </Text>
        <Text ml={8}>
          {'\u2022 '}
          {t('Surname')}
        </Text>
        <Text ml={8}>
          {'\u2022 '}
          {t('Email')}
        </Text>
        <Text ml={8}>
          {'\u2022 '}
          {t('Password')}
        </Text>
        <Text ml={8}>
          {'\u2022 '}
          {t('Phone')}
        </Text>
        <Text ml={8}>
          {'\u2022 '}
          {t('Country')}
        </Text>
        <Text ml={8}>
          {'\u2022 '}
          {t('Birth date')}
        </Text>
        <Text ml={8}>
          {'\u2022 '}
          {t('Gender')}
        </Text>
        <Text ml={8}>
          {'\u2022 '}
          {t('Language')}
        </Text>
        <Text mb={2} mt={2}>
          {t(
            'Even if you aren’t signed in to a {{ projectName }} Account, you might choose to provide us with information — like an email address to communicate with {{ projectName }} or receive updates about our services. We also collect the content you create, upload, or receive from others when using our services. This may include things like messages you write and receive, photos and videos you save, docs and spreadsheets you create.',
            {
              projectName: props.projectName,
            }
          )}
        </Text>
        <Heading mb={3} mt={3} size="md">
          {t('Information we collect as you use our services ')}
        </Heading>
        <Heading mb={2} size="sm">
          {t('Your apps, browsers & devices ')}
        </Heading>
        <Text mb={2}>
          {t(
            'Information we collect as you use our services Your apps, browsers & devices We collect information about the apps, browsers, and devices you use to access {{ projectName }} services, which helps us provide features like automatic product updates and dimming your screen if your battery runs low. ',
            {
              projectName: props.projectName,
            }
          )}
        </Text>
        <Text mb={2}>
          {t(
            'The information we collect includes unique identifiers, browser type and settings, device type and settings, operating system, mobile network information including carrier name and phone number, and application version number. We also collect information about the interaction of your apps, browsers, and devices with our services, including IP address, crash reports, system activity, and the date, time, and referrer URL of your request. '
          )}
        </Text>
        <Text mb={2}>
          {t(
            "We collect this information when a {{ projectName }} service on your device contacts our servers — for example, when you install an app from the Play Store or when a service checks for automatic updates. This information includes things like your device type and carrier name, crash reports, which apps you've installed, and, depending on your device settings, other information about how you’re using your Android device.",
            {
              projectName: props.projectName,
            }
          )}
        </Text>
        <Heading mb={2} mt={2} size="sm">
          {t('Your activity')}
        </Heading>
        <Text mb={2}>{t('We collect information about your activity in our services. The activity information we collect may include:')}</Text>
        <Text ml={8}>
          {'\u2022 '}
          {t('Tag you register.')}
        </Text>
        <Text ml={8}>
          {'\u2022 '}
          {t('Tags you transfer.')}
        </Text>
        <Heading mb={2} mt={2} size="sm">
          {t('Your location information ')}
        </Heading>
        <Text mb={2}>
          {t(
            'We collect information about your location when you use our services, which helps us offer features or services for things near you or based on your general location. '
          )}
        </Text>
        <Text mb={2}>{t('Your location can be determined with varying degrees of accuracy by: ')}</Text>
        <Text ml={8}>
          {'\u2022 '}
          {t('GPS and other sensor data from your device.')}
        </Text>
        <Text ml={8}>
          {'\u2022 '}
          {t('IP address.')}
        </Text>
        <Text ml={8}>
          {'\u2022 '}
          {t('Activity on {{ projectName }} services, such as your searches and places you label like home or work.', {
            projectName: props.projectName,
          })}
        </Text>
        <Text ml={8}>
          {'\u2022 '}
          {t('Information about things near your device, such as Wi-Fi access points, cell towers, and Bluetooth enabled devices.')}
        </Text>
        <Text mb={2} mt={2}>
          {t(
            'The types of location data we collect and how long we store it depend in part on your device and account settings. For example, you can turn your Android or IOS device’s location on or off using the device’s settings app. You can also turn on Location History if you want to create a private map of where you go with your signedin devices. '
          )}
        </Text>
        <Text mb={2}>
          {t(
            'And if your Web & App Activity setting is enabled, your searches and other activity from {{ projectName }} services, which may also include location information, is saved to your {{ projectName }} Account.  ',
            {
              projectName: props.projectName,
            }
          )}
        </Text>
        <Text mb={2}>
          {t(
            'In some circumstances, {{ projectName }} also collects information about you from publicly accessible sources. We may also collect information about you from trusted partners, marketing partners who provide us with information about potential customers of our business services, and security partners who provide us with information to protect against abuse. ',
            {
              projectName: props.projectName,
            }
          )}
        </Text>
        <Text mb={2}>
          {t(
            'We also receive information from advertising partners to provide advertising and research services on their behalf. We use various technologies to collect and store information, including cookies, pixel tags, local storage, such as browser web storage or application data caches, databases, and server logs.'
          )}
        </Text>
        <Heading mb={3} mt={3} size="md">
          {t('Tracking Technologies and Cookies')}
        </Heading>
        <Text mb={2}>
          {t(
            'We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service. The technologies We use may include:'
          )}
        </Text>
        <Text ml={8}>
          {'\u2022 '}
          {t(
            'Cookies or Browser Cookies. A cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service. Unless you have adjusted Your browser setting so that it will refuse Cookies, our Service may use Cookies.'
          )}
        </Text>
        <Text ml={8}>
          {'\u2022 '}
          {t(
            'Web Beacons. Certain sections of our Service and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server integrity).'
          )}
        </Text>
        <Text mb={2} mt={2}>
          {t(
            'Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on Your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close Your web browser. You can learn more about cookies on TermsFeed website article.'
          )}
        </Text>
        <Text mb={2}>
          {t(
            'For more information about the cookies we use and your choices regarding cookies, please visit our Cookies Policy or the Cookies section of our Privacy Policy.'
          )}
        </Text>
        <Heading mb={3} mt={3} size="md">
          {t('How will we use your data?')}
        </Heading>
        <Text mb={2}>{t('We use the information we collect from all our services for the following purposes:')}</Text>
        <Heading mb={2} mt={2} size="sm">
          {t('Provide our services ')}
        </Heading>
        <Text mb={2}>
          {t(
            'We use your information to deliver our services, like storing the tags you claim to share product content with you and contact you with the product creator or brand.'
          )}
        </Text>
        <Text mb={2}>
          {t('When you claim a tag, you give explicit consent to share specific personal information with the brand owner, a registered user on {{ projectName }} . ', {
            projectName: props.projectName,
          })}
        </Text>
        <Heading mb={2} mt={2} size="sm">
          {t('Maintain & improve our services ')}
        </Heading>
        <Text mb={2}>
          {t(
            'We also use your information to ensure our services are working as intended, such as tracking outages or troubleshooting issues that you report to us. And we use your information to make improvements to our services'
          )}
        </Text>
        <Heading mb={2} mt={2} size="sm">
          {t('Develop new services')}
        </Heading>
        <Text mb={2}>{t('We use the information we collect in existing services to help us develop new ones.')}</Text>
        <Heading mb={3} mt={3} size="md">
          {t('Provide personalized services, including content and ads')}
        </Heading>
        <Text mb={2}>
          {t(
            'We use the information we collect to customize our services for you, including changing the language, personalized content, personalized texts based on your gender preferences. Depending on your settings, we may also show you personalized ads based on your interests. We don’t show you personalized ads based on sensitive categories, such as race, religion, sexual orientation, or health. We don’t show you personalized ads based on your content. We don’t share information that personally identifies you with advertisers, such as your name or email, unless you ask us to. '
          )}
        </Text>
        <Heading mb={3} mt={3} size="md">
          {t('Measure performance')}
        </Heading>
        <Text mb={2}>
          {t(
            'We use data for analytics and measurement to understand how our services are used. For example, we analyze data about your visits to our sites to do things like optimize product design. And we also use data about the ads you interact with to help advertisers understand the performance of their ad campaigns. We use a variety of tools to do this, including Google Analytics. When you visit sites or use apps that use Google Analytics, a Google Analytics customer may choose to enable Google to link information about your activity from that site or app with activity from other sites or apps that use our ad services.'
          )}
        </Text>
        <Heading mb={3} mt={3} size="md">
          {t('Communicate with you')}
        </Heading>
        <Text mb={2}>
          {t(
            'We use information we collect, like your email address, to interact with you directly. For example, we may send you a notification if we detect suspicious activity. Or we may let you know about upcoming changes or improvements to our services. And if you contact {{ projectName }} , we’ll keep a record of your request in order to help solve any issues you might be facing.',
            {
              projectName: props.projectName,
            }
          )}
        </Text>
        <Heading mb={3} mt={3} size="md">
          {t('Security')}
        </Heading>
        <Text mb={2}>
          {t(
            'We use information to help improve the safety and reliability of our services. This includes detecting, preventing, and responding to fraud, abuse, security risks, and technical issues that could harm {{ projectName }} , our users, or the public. We analyze your activity to help us detect abuse such as spam, malware, and illegal content. We also use algorithms to recognize patterns in data.',
            {
              projectName: props.projectName,
            }
          )}
        </Text>
        <Text mb={2}>{t('We may combine the information we collect among our services and across your devices for the purposes described above.')}</Text>
        <Text mb={2}>{t('We’ll ask for your consent before using your information for a purpose that isn’t covered in this Privacy Policy.')}</Text>
        <Heading mb={3} mt={3} size="md">
          {t("You have choices regarding the information we collect and how it's used")}
        </Heading>
        <Text mb={2}>{t('This section describes key controls for managing your privacy across our services.')}</Text>
        <Heading mb={2} mt={2} size="sm">
          {t('Managing, reviewing, and updating your information ')}
        </Heading>
        <Text mb={2}>{t('When you’re signed in, you can always review and update information by visiting your Profile.')}</Text>
        <Heading mb={2} mt={2} size="sm">
          {t('Exporting, removing & deleting your information')}
        </Heading>
        <Text mb={2}>
          {t(
            'You can export a copy of content if you want to back it up or use it with a service outside of {{ projectName }}  requesting a file export using the contact email address {{ email }}',
            {
              projectName: props.projectName,
              email: props.company.contactEmail,
            }
          )}
        </Text>
        <Heading mb={3} mt={3} size="md">
          {t('When you share your information')}
        </Heading>
        <Text mb={2}>
          {t(
            'Many of our services let you share information with other people, and you have control over how you share. When you claim an NFC tag, you give explicit consent to share specific personal information with the brand owner, a registered user on {{ projectName }} . ',
            {
              projectName: props.projectName,
            }
          )}
        </Text>
        <Heading mb={3} mt={3} size="md">
          {t('When {{ projectName }} shares your information ', {
            projectName: props.projectName,
          })}
        </Heading>
        <Text mb={2}>
          {t('We do not share your personal information with companies, organizations, or individuals outside {{ projectName }}  except in the following cases: ', {
            projectName: props.projectName,
          })}
        </Text>
        <Heading mb={2} mt={2} size="sm">
          {t('With your consent')}
        </Heading>
        <Text mb={2}>{t('We may disclose Your personal information for any other purpose with Your consent.')}</Text>
        <Heading mb={2} mt={2} size="sm">
          {t('With Service Providers')}
        </Heading>
        <Text mb={2}>
          {t('We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to contact You. They may be able to:')}
        </Text>
        <Text ml={8}>
          {'\u2022 '}
          {t('Access and retain information stored in your account. ')}
        </Text>
        <Text ml={8}>
          {'\u2022 '}
          {t('View statistics regarding your account. ')}
        </Text>
        <Text ml={8}>
          {'\u2022 '}
          {t('Change your account password Suspend or terminate your account access. ')}
        </Text>
        <Text ml={8}>
          {'\u2022 '}
          {t('Receive your account information in order to satisfy applicable law, regulation, legal process, or enforceable governmental request.')}
        </Text>
        <Text ml={8}>
          {'\u2022 '}
          {t('Restrict your ability to delete or edit your information or your privacy settings.')}
        </Text>
        <Heading mb={2} mt={2} size="sm">
          {t('For business transfers')}
        </Heading>
        <Text mb={2}>
          {t(
            'We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.'
          )}
        </Text>
        <Heading mb={2} mt={2} size="sm">
          {t('With Affiliates')}
        </Heading>
        <Text mb={2}>
          {t(
            'We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.'
          )}
        </Text>
        <Heading mb={2} mt={2} size="sm">
          {t('With business partners')}
        </Heading>
        <Text mb={2}>{t('We may share Your information with Our business partners to offer You certain products, services or promotions.')}</Text>
        <Heading mb={2} mt={2} size="sm">
          {t('With other users')}
        </Heading>
        <Text mb={2}>
          {t(
            'When You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside. If You interact with other users or register through a Third-Party Social Media Service, Your contacts on the Third-Party Social Media Service may see Your name, profile, pictures and description of Your activity. Similarly, other users will be able to view descriptions of Your activity, communicate with You and view Your profile.'
          )}
        </Text>
        <Heading mb={2} mt={2} size="sm">
          {t('For legal reasons')}
        </Heading>
        <Text mb={2}>
          {t(
            'We will share personal information outside of {{ projectName }}  if we have a good-faith belief that access, use, preservation, or disclosure of the information is reasonably necessary to:',
            {
              projectName: props.projectName,
            }
          )}
        </Text>
        <Text mb={2}>{t('Meet any applicable law, regulation, legal process, or enforceable governmental request. ')}</Text>
        <Text mb={2}>{t('Enforce applicable Terms of Service, including investigation of potential violations.')}</Text>
        <Text mb={2}>{t('Detect, prevent, or otherwise address fraud, security, or technical issues. ')}</Text>
        <Text mb={2}>
          {t('Protect against harm to the rights, property or safety of {{ projectName }} , our users, or the public as required or permitted by law. ', {
            projectName: props.projectName,
          })}
        </Text>
        <Text mb={2}>
          {t(
            'We may share non-personally identifiable information publicly and with our partners — like publishers, advertisers, developers, or rights holders. For example, we share information publicly to show trends about the general use of our services. We also allow specific partners to collect information from your browser or device for advertising and measurement purposes using their own cookies or similar technologies. '
          )}
        </Text>
        <Heading mb={3} mt={3} size="md">
          {t('We build security into our services to protect your information.')}
        </Heading>
        <Text mb={2}>
          {t(
            'All {{ projectName }}  products are built with strong security features that continuously protect your information. The insights we gain from maintaining our services help us detect and automatically block security threats from ever reaching you. And if we do detect something risky that we think you should know about, we’ll notify you and help guide you through steps to stay better protected. We work hard to protect you from unauthorized access, alteration, disclosure, or destruction of information we hold, including: We use encryption to keep your data private while in transit We offer a range of security features, like Safe Browsing, Security Checkup to help you protect your account.',
            {
              projectName: props.projectName,
            }
          )}
        </Text>
        <Text mb={2}>
          {t('We review our information collection, storage, and processing practices, including physical security measures, to prevent unauthorized access to our systems.')}
        </Text>
        <Text mb={2}>
          {t(
            'We restrict access to personal information to {{ projectName }}  employees, contractors, and agents who need that information in order to process it. Anyone with this access is subject to strict contractual confidentiality obligations and may be disciplined or terminated if they fail to meet these obligations.',
            {
              projectName: props.projectName,
            }
          )}
        </Text>
        <Heading mb={3} mt={3} size="md">
          {t('You can export a copy of your information or delete it')}
        </Heading>
        <Text mb={2}>
          {t(
            'You can export a copy of the content in your {{ projectName }}  Account if you want to back it up or use it with a service outside sending an email to {{ email }}.  You can delete your entire {{ projectName }}  account from your profile page.',
            {
              projectName: props.projectName,
              email: props.company.contactEmail,
            }
          )}
        </Text>
        <Text mb={2}>{t('We retain the data we collect for different periods of time depending on what it is, how we use it, and how you configure your settings:')}</Text>
        <Text mb={2}>
          {t(
            'Some data you can delete whenever you like, such as your personal info. You can also delete activity information saved in your account, or choose to have it deleted automatically after a set period of time. '
          )}
        </Text>
        <Heading mb={3} mt={3} size="md">
          {t('Retention of Your Personal Data')}
        </Heading>
        <Text mb={2}>
          {t(
            'We’ll keep this data in your {{ projectName }}  Account until you remove it or choose to have it removed. Other data is deleted or anonymized automatically after a set period of time, such as data in server logs. We keep some data until you delete your {{ projectName }}  and some data we retain for longer periods of time when necessary for legitimate business or legal purposes, such as security, fraud and abuse prevention, or financial record-keeping. ',
            {
              projectName: props.projectName,
            }
          )}
        </Text>
        <Text mb={2}>
          {t(
            'The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.'
          )}
        </Text>
        <Text mb={2}>
          {t(
            'The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.'
          )}
        </Text>
        <Text mb={2}>
          {t(
            'When you delete data, we follow a deletion process to make sure that your data is safely and completely removed from our servers or retained only in anonymized form. We try to ensure that our services protect information from accidental or malicious deletion. Because of this, there may be delays between when you delete something and when copies are deleted from our active and backup systems. '
          )}
        </Text>
        <Heading mb={3} mt={3} size="md">
          {t("Children's Privacy")}
        </Heading>
        <Text mb={2}>
          {t(
            'Our Service does not address anyone under the age of 14. We do not knowingly collect personally identifiable information from anyone under the age of 14. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 14 without verification of parental consent, We take steps to remove that information from Our servers. '
          )}
        </Text>
        <Text mb={2}>
          {t(
            "If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information."
          )}
        </Text>
        <Heading mb={3} mt={3} size="md">
          {t('Links to Other Websites')}
        </Heading>
        <Text mb={2}>
          {t(
            "Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit. "
          )}
        </Text>
        <Text mb={2}>{t('We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.')}</Text>
        <Heading fontSize="2xl" mt={5} mb={3}>
          {t('European requirements ')}
        </Heading>
        <Heading mb={3} mt={3} size="md">
          {t('How to exercise your rights and contact {{ projectName }} ', {
            projectName: props.projectName,
          })}
        </Heading>
        <Text mb={2}>
          {t(
            'If European Union (EU) or United Kingdom (UK) data protection law applies to the processing of your information, we provide the controls described in this policy so you can exercise your rights to request access to, update, remove, and restrict the processing of your information. You also have the right to object to the processing of your information or export your information to another service. '
          )}
        </Text>
        <Text mb={2}>
          {t(
            'If you have additional questions or requests related to your rights, you can contact {{ projectName }}  and our data protection office. And you can contact your local data protection authority if you have concerns regarding your rights under local law. ',
            {
              projectName: props.projectName,
            }
          )}
        </Text>
        <Heading mb={3} mt={3} size="md">
          {t('Data controller')}
        </Heading>
        <Text mb={2}>
          {t('Unless otherwise stated in a service-specific privacy notice, the data controller responsible for processing your information depends on where you are based: ')}
        </Text>
        <Text mb={2}>
          {t('Amazon wu-west-3 zone for users of {{ projectName }}  services based in the European Economic Area or Switzerland, located at Paris. ', {
            projectName: props.projectName,
          })}
        </Text>
        <Heading mb={3} mt={3} size="md">
          {t('Legal bases of processing ')}
        </Heading>
        <Text mb={2}>{t('We process your information for the purposes described in this policy, based on the following legal grounds: ')}</Text>
        <Heading mb={2} mt={2} size="sm">
          {t('With your consent ')}
        </Heading>
        <Text mb={2}>
          {t(
            'We ask for your agreement to process your information for specific purposes and you have the right to withdraw your consent at any time. You can manage these settings in your {{ projectName }} Account. As described in the Sharing your information section, we’ll share personal information outside of {{ projectName }} when we have your consent, and provide you with controls to review and manage third party apps and sites you have given access to data in your {{ projectName }} Account. ',
            {
              projectName: props.projectName,
            }
          )}
        </Text>
        <Heading mb={3} mt={3} size="md">
          {t('When we’re pursuing legitimate interests ')}
        </Heading>
        <Text mb={2}>
          {t(
            'We process your information for our legitimate interests and those of third parties while applying appropriate safeguards that protect your privacy. This means that we process your information for things like:  '
          )}
        </Text>
        <Text ml={8}>
          {'\u2022 '}
          {t('Providing, maintaining, and improving our services to meet the needs of our users ')}
        </Text>
        <Text ml={8}>
          {'\u2022 '}
          {t('Developing new products and features that are useful for our users ')}
        </Text>
        <Text ml={8}>
          {'\u2022 '}
          {t('Understanding how people use our services to ensure and improve the performance of our services ')}
        </Text>
        <Text ml={8}>
          {'\u2022 '}
          {t('Customizing our services to provide you with a better user experience (and, if relevant, tailoring the experience to be age-appropriate) ')}
        </Text>
        <Text ml={8}>
          {'\u2022 '}
          {t(
            'Marketing to inform users about our services Providing advertising, which allows us to offer many of our services without a fee (and when ads are personalized, we ask for your consent) '
          )}
        </Text>
        <Text ml={8}>
          {'\u2022 '}
          {t('Detecting, preventing, or otherwise addressing fraud, abuse, security, or technical issues with our services ')}
        </Text>
        <Text ml={8}>
          {'\u2022 '}
          {t(
            'Protecting against harm to the rights, property or safety of {{ projectName }} , our users, or the public as required or permitted by law, including disclosing information to government authorities',
            {
              projectName: props.projectName,
            }
          )}
        </Text>
        <Text ml={8}>
          {'\u2022 '}
          {t('Performing research that improves our services for our users and benefits the public ')}
        </Text>
        <Text ml={8}>
          {'\u2022 '}
          {t(
            'Fulfilling obligations to our partners like developers and rights holders Enforcing legal claims, including investigation of potential violations of applicable Terms of Service '
          )}
        </Text>
        <Heading mb={2} mt={2} size="sm">
          {t('When we’re providing a service ')}
        </Heading>
        <Text mb={2}>{t('We process some data in order to provide a service you’ve asked for under a contract. ')}</Text>
        <Heading mb={2} mt={2} size="sm">
          {t('When we’re complying with legal obligations  ')}
        </Heading>
        <Text mb={2}>
          {t(
            'We’ll process your data when we have a legal obligation to do so, for example, if we’re responding to legal process or an enforceable governmental request. As another example, legal obligations sometimes require us to retain certain information for purposes like financial record-keeping, such as information about a payment you’ve made to {{ projectName }}  for tax or accounting purposes.',
            {
              projectName: props.projectName,
            }
          )}
        </Text>
        <Heading fontSize="2xl" mt={5} mb={3}>
          {t('When this policy applies  ')}
        </Heading>
        <Text mb={2}>
          {t(
            'This Privacy Policy applies to all of the services offered by {{ projectName }} and its affiliates, and services offered on third-party sites, such as advertising services. This Privacy Policy doesn’t apply to services that have separate privacy policies that do not incorporate this Privacy Policy. This Privacy Policy doesn’t apply to: The information practices of other companies and organizations that advertise our services Services offered by other companies or individuals, including products or sites they offer that may include {{ projectName }} services to which the policy applies, or products or sites displayed to you in search results, or linked from our services ',
            {
              projectName: props.projectName,
            }
          )}
        </Text>
        <Heading fontSize="2xl" mt={5} mb={3}>
          {t('Changes to this policy')}
        </Heading>
        <Text mb={2}>
          {t(
            'We change this Privacy Policy from time to time. We will not reduce your rights under this Privacy Policy without your explicit consent. We always indicate the date the last changes were published and we offer access to archived versions for your review.'
          )}
        </Text>
        <Heading mb={3} mt={3} size="md">
          {t('Contact Us')}
        </Heading>
        <Text mb={2}>{t('If you have any questions about this Privacy Policy, You can contact us:')}</Text>
        <Text ml={8}>
          {t('By email: {{ email }}', {
            email: props.company.contactEmail,
          })}
        </Text>
        <Text ml={8}>
          {t('By mail: {{ address }}', {
            address: props.company.address,
          })}
        </Text>
      </>
    </CommonLayout>
  );
};

export default PrivacyPolicyScreen;
