import io, {Socket} from 'socket.io-client';
import {SocketSignals} from '@bri/rotimpres-core';
import {COOKIES_TYPES, CookiesService} from '@bri/shared-components';

// Importa EventEmitter de 'events' en lugar de 'react-native'
import {EventEmitter} from 'events';
import Config from '../config/config';

class SocketService {
  socket?: Socket = undefined;
  eventTarget: EventEmitter;
  signalNewNotification?: () => void;

  constructor(signalNewNotification?: () => void) {
    this.eventTarget = new EventEmitter();
    this.signalNewNotification = signalNewNotification;
  }

  async init() {
    const token = await CookiesService.getType(COOKIES_TYPES.TECHNICAL, 'access_token');
    if (!token) {
      return;
    }

    const options = {
      query: {
        token,
      },
      transports: ['websocket'],
      reconnectionAttempts: 5,
    };

    const socket = io(Config.baseService.baseUrl, options);

    socket.on('connect', () => {
      // Fer algo al conectar
      socket.emit('sendSocketId', socket.id);
    });

    socket.on(SocketSignals.NEW_NOTIFICATION, data => {
      // S'ha rebut senyal de nova notificació
      if (this.signalNewNotification) {
        this.signalNewNotification();
      }
    });

    socket.on('disconnect', () => {
      // Fer algo al desconectar
    });
  }

  finish() {
    if (this.socket) {
      this.socket.close();
    }
  }
}

export default SocketService;
