import {ScrollView} from 'native-base';
import React from 'react';

type Props = {
  children: JSX.Element;
};

const CommonLayout = ({children}: Props) => <ScrollView
  contentContainerStyle={{paddingHorizontal: 20, paddingVertical: 10}}>{children}</ScrollView>;

export default CommonLayout;
