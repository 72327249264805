import {View, Text, useTheme} from 'native-base';
import React, {FC, useState} from 'react';
import {TouchableOpacity, StyleSheet, ScrollView} from 'react-native';

type Props = {
  title: string;
  children: JSX.Element;
};

const AccordionItem: FC<Props> = props => {
  const [expanded, setExpanded] = useState(false);
  const theme = useTheme();

  const stylesAccord = StyleSheet.create({
    container: {
      paddingBottom: 4,
      borderBottomWidth: 1,
      borderBottomColor: theme.colors.muted[50],
    },
    header: {
      borderRadius: 10,
      padding: 12,
      backgroundColor: theme.colors.primary[500],
      color: theme.colors.muted[50],
      minHeight: 50,
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    body: {
      padding: 12,
    },
    title: {
      color: theme.colors.muted[50],
      fontSize: theme.fontSizes.md,
    },
  });

  const toggleItem = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <View style={stylesAccord.container}>
        <TouchableOpacity style={stylesAccord.header} onPress={toggleItem}>
          <Text style={stylesAccord.title}>{props.title}</Text>
        </TouchableOpacity>
        {expanded && (
          <ScrollView style={stylesAccord.body} showsVerticalScrollIndicator={false}>
            {props.children}
          </ScrollView>
        )}
      </View>
    </>
  );
};

export default AccordionItem;
