import {BottomTabScreenProps} from '@react-navigation/bottom-tabs';
import React, {FC, useEffect, useState} from 'react';
import {CLIENT_ERROR_CODES_ROTIMPRES, IProduct, ITag} from '@bri/rotimpres-core';
import {Heading, ScrollView, Spinner, Text, View, Image, VStack, theme} from 'native-base';
import {useTranslation} from 'react-i18next';
import {ScreensParams} from '../navigators/screens';
import {getMultilanguageTranslation} from '../utils/languageUtils';
import {Images, getResource} from '../utils/imagesUtils';

type Props = BottomTabScreenProps<ScreensParams, 'Promotion'>;

export const PromotionScreen: FC<Props> = props => {
  const data = props.route.params?.additionalData;
  const {t} = useTranslation();

  const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  const image = data?.image ? data?.image : Images.product;
  const expirationDate = new Date(data?.expiration_date);

  const day = expirationDate.getDate();
  const month = t(monthNames[expirationDate.getMonth()]);
  const year = expirationDate.getFullYear();

  return (
    <ScrollView showsVerticalScrollIndicator={false} contentContainerStyle={{flexGrow: 1}}>
      <View px={5} py={3} flex={1}>
        <VStack mt={5} justifyContent="center" flex={1} flexBasis="min-content">
          <Image rounded="12" width="100%" minH="200px" source={getResource(image)}
resizeMode="center" />
        </VStack>

        <VStack mt={5} alignItems="center" flex={1} justifyContent="space-around" px={5} flexBasis="min-content">
          <VStack alignItems="center" space={5} flex={1} flexBasis="min-content">
            <Text textAlign="center" bold fontSize="lg" color="primary.500">
              {getMultilanguageTranslation(data?.description)}
            </Text>
            <Text textAlign="center" fontSize="3xl" paddingBottom={20}>
              {getMultilanguageTranslation(data.client)}
            </Text>

            <Text fontSize="3xl" bold>
              {data.promotionCode}
            </Text>
            <Text fontSize="xs" bold color="gray.500">
              {t(`Valid until {{month}} {{day}}, {{year}}`, {month, day, year})}
            </Text>
          </VStack>
        </VStack>
      </View>
    </ScrollView>
  );
};
