import {useRecoilState} from 'recoil';
import {loggedUser} from '@bri/shared-components';
import React, {useEffect, useState} from 'react';
import {IProduct} from '@bri/rotimpres-core';
import {Heading, ScrollView, Text, View} from 'native-base';
import {useTranslation} from 'react-i18next';
import {BottomTabScreenProps} from '@react-navigation/bottom-tabs';
import {useFocusEffect} from '@react-navigation/native';
import {useProductsService} from '../services/ProductsService';
import {ItemList} from '../components/ItemList';
import {ScreensParams} from '../navigators/screens';
import {ItemInfo, ItemType} from '../components/Item';

type Props = BottomTabScreenProps<ScreensParams, 'ProductListScreen'>;
export const ProductsListScreen = () => {
  const [user] = useRecoilState(loggedUser);
  const isAuthenticated = !!user;
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const productsService = useProductsService();
  const [products, setProducts] = useState<IProduct[]>();
  const {t} = useTranslation();
  let productsList: ItemInfo[] = [];

  const userID = user?._id;

  useFocusEffect(
    React.useCallback(() => {
      if (userID) {
        productsService
          .listUserTags({owner: String(userID)})
          .apiResponse(() => {
            setLoading(false);
          })
          .response(async resp => {
            setProducts(resp.products);
          })
          .error(err => console.log(err))
          .finally(() => setLoading(false));
      } else {
        setLoading(false);
      }
    }, [])
  );

  if (loading) return null;

  if (products && products?.length > 0) {
    productsList = products.map((product: any) => ({
      id: product.tagID,
      name: product.name,
      code: product.code,
      expiration_date: product.expiration_date,
      image: product.image,
      type: 'PRODUCT',
    }));
  } else {
    return (
      <Heading color="primary.500" size="lg" fontSize="lg" bold
paddingTop="45px" textAlign="center">
        {t("You haven't acquired any product yet, scan an NFC Tag to add it to your list.")}
      </Heading>
    );
  }

  return (
    <ScrollView showsVerticalScrollIndicator={false}>
      <View mt={5}>
        <ItemList title={t('My tokens') as string} data={productsList} type={ItemType.Product} />
      </View>
    </ScrollView>
  );
};
