import {extendTheme} from 'native-base';

export const RotimpresTheme = extendTheme({
  fontConfig: {
    Roboto: {
      200: {
        normal: 'Roboto-Light',
        italic: 'Roboto-LightItalic',
      },
      300: {
        normal: 'Roboto-Regular',
        italic: 'Roboto-Italic',
      },
      400: {
        normal: 'Roboto-Medium',
        italic: 'Roboto-MediumItalic',
      },
      500: {
        normal: 'Roboto-Medium',
        italic: 'Roboto-MediumItalic',
      },
      600: {
        normal: 'Roboto-Bold',
        italic: 'Roboto-BoldItalic',
      },
      700: {
        normal: 'Roboto-Bold',
        italic: 'Roboto-BoldItalic',
      },
      800: {
        normal: 'Roboto-Black',
        italic: 'Roboto-BlackItalic',
      },
    },
  },
  colors: {
    primary: {
      100: '#d97706',
      200: '#d97706',
      300: '#d97706',
      400: '#d97706',
      500: '#0059A7',
      600: '#0860B2',
      700: '#0E5290',
      800: '#d97706',
      900: '#d97706',
    },
    secondary: {
      100: '#d97706',
      200: '#d97706',
      300: '#d97706',
      400: '#d97706',
      500: '#C3E3F6',
      600: '#0059A7',
      700: '#C3E3F6',
      800: '#d97706',
      900: '#d97706',
    },
    error: {
      600: '#f87171',
    },
  },
  components: {
    Button: {
      baseStyle: {
        rounded: '3xl',
      },
      variants: {
        register: ({colorScheme}: any) => ({
          backgroundColor: 'secondary.500',
          color: 'primary.500',
        }),
        contact: ({colorScheme}: any) => ({
          backgroundColor: 'primary.500',
        }),
      },
    },
    FormControlLabel: {
      variants: {
        register: ({colorScheme}: any) => ({
          _text: {
            color: 'muted.50',
            bold: true,
          },
          _astrick: {
            color: 'muted.50',
          },
        }),
        contact: ({colorScheme}: any) => ({
          _text: {
            color: 'primary.500',
            bold: true,
          },
          _astrick: {
            color: 'primary.500',
          },
        }),
      },
    },
    Checkbox: {
      variants: {
        register: ({colorScheme}: any) => ({
          bgColor: 'primary.500',
          borderColor: 'secondary.500',
          _text: {
            color: 'muted.50',
            bold: false,
            fontWeight: 'light',
          },
          _checked: {bgColor: 'secondary.500', borderColor: 'secondary.500', _hover: {borderColor: 'secondary.500'}},
          _hover: {borderColor: 'secondary.500'},

          _icon: {color: 'primary.500'},
        }),
      },
    },
    Select: {
      baseStyle: {
        borderBottomColor: 'muted.50',
        borderLeftColor: 'muted.50',
        borderRightColor: 'muted.50',
        borderTopColor: 'muted.50',
        borderWidth: 2,
        _focus: {
          borderBottomColor: 'muted.50',
          borderLeftColor: 'muted.50',
          borderRightColor: 'muted.50',
          borderTopColor: 'muted.50',
        },
        _hover: {
          borderBottomColor: 'muted.50',
          borderLeftColor: 'muted.50',
          borderRightColor: 'muted.50',
          borderTopColor: 'muted.50',
        },
        color: 'secondary.500',
      },
    },
    Input: {
      defaultProps: (props: any) => {
        if (props.variant === 'register') {
          return {
            size: 'sm',
          };
        }
        return {};
      },
      variants: {
        register: ({colorScheme}: any) => ({
          // borderBottomColor: 'muted.50',
          // borderLeftColor: 'muted.50',
          // borderRightColor: 'muted.50',
          // borderTopColor: 'muted.50',
          borderWidth: 2,
          color: 'secondary.500',
          size: 'xl',
          _web: {
            style: {
              size: 'xl',
            },
          },
          _focus: {
            borderBottomColor: 'muted.50',
            borderLeftColor: 'muted.50',
            borderRightColor: 'muted.50',
            borderTopColor: 'muted.50',
          },
          _hover: {
            borderBottomColor: 'muted.50',
            borderLeftColor: 'muted.50',
            borderRightColor: 'muted.50',
            borderTopColor: 'muted.50',
          },
        }),
        contact: ({colorScheme}: any) => ({
          borderWidth: 2,
          color: 'primary.500',
          size: 'xl',
          _focus: {
            borderBottomColor: 'primary.500',
            borderLeftColor: 'primary.500',
            borderRightColor: 'primary.500',
            borderTopColor: 'primary.500',
          },
          _hover: {
            borderBottomColor: 'primary.500',
            borderLeftColor: 'primary.500',
            borderRightColor: 'primary.500',
            borderTopColor: 'primary.500',
          },
        }),
      },
    },
  },
  fonts: {
    heading: 'Roboto',
    body: 'Roboto',
    mono: 'Roboto',
  },

  config: {
    // Changing initialColorMode to 'dark'
    initialColorMode: 'light',
    // useSystemColorMode: true,
    // accessibleColors: true
  },
});
