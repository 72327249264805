import React from 'react';
import {IconButton, useTheme} from 'native-base';
import {useTranslation} from 'react-i18next';
import {createStackNavigator, StackNavigationOptions} from '@react-navigation/stack';
import {useRecoilState} from 'recoil';
import {loggedUser} from '@bri/shared-components';
import {useNavigation} from '@react-navigation/core';
import {FontAwesome} from '@native-base/icons';
import {ScreensParams} from './screens';
import {RegisterScreen} from '../screens/RegisterScreen';
import {LoginScreen} from '../screens/LoginScreen';
import {RecoverEmailClient} from '../screens/RecoverEmailScreen';
import CookiesScreen from '../screens/common/CookiesScreen';
import {HomeScreen} from '../screens/HomeScreen';
import TabNavigator from './TabNavigator';
import PrivacyPolicyScreen from '../screens/common/PrivacyPolicyScreen';
import TermsAndConditionsScreen from '../screens/common/TermsAndConditionsScreen';
import CookiesConsentScreen from '../screens/CookiesConsentScreen';
import {ValidateScreen} from '../screens/ValidateScreen';
import {DevolutionMapScreen} from '../screens/DevolutionMapScreen';
import {PreviewProductScreen} from '../screens/PreviewProductScreen';
import {ContactScreen} from '../screens/ContactScreen';
import {NotificationScreen} from '../screens/NotificationScreen';
import {ChangePasswordClient} from '../screens/ChangePasswordScreen';
import {NotificationSettingsScreen} from '../screens/NotificationSettingsScreen';
import FrequentlyAskedQuestionsScreen from '../screens/common/FrequentlyAskedQuestionsScreen';
import {PromotionScreen} from '../screens/PromotionScreen';

const Stack = createStackNavigator<ScreensParams>();

const StackNavigator = () => {
  const theme = useTheme();
  const navigation = useNavigation<any>();
  const {t} = useTranslation();
  const [user] = useRecoilState(loggedUser);
  const isAuthenticated = !!user;

  const headerStyle: StackNavigationOptions = {
    headerShown: true,
    headerStyle: {backgroundColor: theme.colors.primary['500'], borderBottomRightRadius: 20, borderBottomLeftRadius: 20, borderBottomWidth: 0},
    headerTitleAlign: 'center',
    headerTitleStyle: {color: 'white', fontSize: 22},
    headerTintColor: 'white',
  };

  const headerStyleNoAuth: StackNavigationOptions = {
    ...headerStyle,
    headerStyle: {backgroundColor: theme.colors.primary['500'], borderBottomWidth: 0},
    headerRight: () => <IconButton _icon={{as: FontAwesome, name: 'envelope', color: 'white', marginRight: 3}} onPress={() => navigation.navigate('Contact')} />,
  };

  const headerStyleNotification: StackNavigationOptions = {
    ...headerStyle,
    headerRight: () => <IconButton _icon={{as: FontAwesome, name: 'cog', color: 'white', marginRight: 3}} onPress={() => navigation.navigate('NotificationSettings')} />,
  };

  const headerStyleScannedProduct: StackNavigationOptions = {
    ...headerStyle,
    headerLeft: () => (
      <IconButton
        _icon={{as: FontAwesome, name: 'home', color: 'white', marginLeft: 3}}
        onPress={() => {
          navigation.reset({
            index: 0,
            routes: [{name: isAuthenticated ? 'App' : 'Home'}],
          });
        }}
      />
    ),
    headerRight: () => <IconButton _icon={{as: FontAwesome, name: 'question-circle-o', color: 'white', marginRight: 3}} onPress={() => navigation.navigate('FAQ')} />,
  };

  return (
    <Stack.Navigator screenOptions={{headerShown: false}} initialRouteName="Home">
      {isAuthenticated ? (
        <>
          <Stack.Screen name="App" component={TabNavigator} />
        </>
      ) : (
        <>
          <Stack.Screen name="Home" component={HomeScreen} />
          <Stack.Screen name="Login" component={LoginScreen} options={{...headerStyleNoAuth, title: ''}} />
          <Stack.Screen name="Register" component={RegisterScreen} options={{...headerStyleNoAuth, title: ''}} />
          <Stack.Screen name="RecoverEmailClient" component={RecoverEmailClient} options={{...headerStyleNoAuth, title: ''}} />
          <Stack.Screen name="ChangePasswordClient" component={ChangePasswordClient} options={{...headerStyleNoAuth, title: ''}} />
        </>
      )}
      <Stack.Group navigationKey={isAuthenticated ? 'user' : 'guest'}>
        <Stack.Screen name="FAQ" component={FrequentlyAskedQuestionsScreen} options={{...headerStyle, title: 'FAQs'}} />
        <Stack.Screen name="PrivacyPolicy" component={PrivacyPolicyScreen} options={{...headerStyle, title: ''}} />
        <Stack.Screen name="Terms" component={TermsAndConditionsScreen} options={{...headerStyle, title: ''}} />
        <Stack.Screen name="Cookies" component={CookiesScreen} options={{...headerStyle, title: ''}} />
        <Stack.Screen name="CookiesConsent" component={CookiesConsentScreen} options={{...headerStyle, title: ''}} />
        <Stack.Screen name="Validate" component={ValidateScreen} options={{...headerStyleScannedProduct, title: t('Product') as string}} />
        <Stack.Screen name="DevolutionMap" component={DevolutionMapScreen} options={{...headerStyle, title: t('Devolution Map') as string}} />
        <Stack.Screen name="Promotion" component={PromotionScreen} options={{...headerStyle, title: t('Promotion') as string}} />
        <Stack.Screen name="PreviewProduct" component={PreviewProductScreen} options={{...headerStyle, title: t('Product') as string}} />
        <Stack.Screen name="Contact" component={ContactScreen} options={{...headerStyle, title: t('Contact') as string}} />
        <Stack.Screen name="Notification" component={NotificationScreen} options={{...headerStyleNotification, title: t('Notification') as string}} />
        <Stack.Screen name="NotificationSettings" component={NotificationSettingsScreen} options={{...headerStyle, title: t('Notifications Settings') as string}} />
      </Stack.Group>
    </Stack.Navigator>
  );
};

export default StackNavigator;
