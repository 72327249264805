import 'react-native-gesture-handler';
import React, { useEffect } from 'react';
import { NativeBaseProvider } from 'native-base';
import './i18n';
import { View } from 'react-native';
import { ConfirmationDialogProvider, SharedComponentsProvider, StorageService } from '@bri/shared-components';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useFonts } from 'expo-font';
import { RecoilRoot,  } from 'recoil';
import { Asset } from 'expo-asset';
import Navigator from './src/navigators/Navigator';
import IPhoneOverlay from './src/components/IPhoneOverlay';
import { MAX_WINDOW_HEIGHT, WINDOW_WIDTH } from './src/utils/mixins';
import { RotimpresTheme } from './src/theme/rotimpres.theme';
import { Images } from './src/utils/imagesUtils';
import Config from './src/config/config';
import { PopUpProvider } from './src/components/PopUpContext';
import { SocketProvider} from './src/components/SocketContext';



export default function App() {
  StorageService.setStorage(AsyncStorage);

  const [fontsLoaded] = useFonts({
    AntDesign: require(`./src/assets/fonts/AntDesign.ttf`),
    Entypo: require(`./src/assets/fonts/Entypo.ttf`),
    EvilIcons: require(`./src/assets/fonts/EvilIcons.ttf`),
    Feather: require(`./src/assets/fonts/Feather.ttf`),
    FontAwesome: require(`./src/assets/fonts/FontAwesome.ttf`),
    FontAwesome5_Brands: require(`./src/assets/fonts/FontAwesome5_Brands.ttf`),
    FontAwesome5_Regular: require(`./src/assets/fonts/FontAwesome5_Regular.ttf`),
    FontAwesome5_Solid: require(`./src/assets/fonts/FontAwesome5_Solid.ttf`),
    Fontisto: require(`./src/assets/fonts/Fontisto.ttf`),
    Foundation: require(`./src/assets/fonts/Foundation.ttf`),
    Ionicons: require(`./src/assets/fonts/Ionicons.ttf`),
    MaterialCommunityIcons: require(`./src/assets/fonts/MaterialCommunityIcons.ttf`),
    MaterialIcons: require(`./src/assets/fonts/MaterialIcons.ttf`),
    Octicons: require(`./src/assets/fonts/Octicons.ttf`),
    SimpleLineIcons: require(`./src/assets/fonts/SimpleLineIcons.ttf`),
    Zocial: require(`./src/assets/fonts/Zocial.ttf`),
    'Roboto-Black': require('./src/assets/fonts/Roboto/Roboto-Black.ttf'),
    'Roboto-BlackItalic': require('./src/assets/fonts/Roboto/Roboto-BlackItalic.ttf'),
    'Roboto-Bold': require('./src/assets/fonts/Roboto/Roboto-Bold.ttf'),
    'Roboto-BoldItalic': require('./src/assets/fonts/Roboto/Roboto-BoldItalic.ttf'),
    'Roboto-Italic': require('./src/assets/fonts/Roboto/Roboto-Italic.ttf'),
    'Roboto-Light': require('./src/assets/fonts/Roboto/Roboto-Light.ttf'),
    'Roboto-LightItalic': require('./src/assets/fonts/Roboto/Roboto-LightItalic.ttf'),
    'Roboto-Medium': require('./src/assets/fonts/Roboto/Roboto-Medium.ttf'),
    'Roboto-MediumItalic': require('./src/assets/fonts/Roboto/Roboto-MediumItalic.ttf'),
    'Roboto-Regular': require('./src/assets/fonts/Roboto/Roboto-Regular.ttf'),
    'Roboto-Thin': require('./src/assets/fonts/Roboto/Roboto-Thin.ttf'),
    'Roboto-ThinItalic': require('./src/assets/fonts/Roboto/Roboto-ThinItalic.ttf'),
  });


  const loadAssets = (assets: Object) => {
    for (const val of Object.values(assets)) {
      if (typeof val === 'object') {
        loadAssets(val);
      } else {
        Asset.loadAsync(val);
      }
    }
  };



  useEffect(() => {
    loadAssets(Images);
  }, []);


  if (!fontsLoaded) {
    return null;
  }


  return (
    <RecoilRoot>
      <NativeBaseProvider theme={RotimpresTheme}>
        <SocketProvider>
          <PopUpProvider>
            <SharedComponentsProvider config={Config}>
              <ConfirmationDialogProvider>
                <IPhoneOverlay />
                <View
                  style={{
                    width: WINDOW_WIDTH,
                    maxWidth: WINDOW_WIDTH,
                    height: '100%',
                    maxHeight: MAX_WINDOW_HEIGHT,
                    margin: 'auto',
                    backgroundColor: 'white',
                  }}>
                  <Navigator />
                </View>
              </ConfirmationDialogProvider>
            </SharedComponentsProvider>
          </PopUpProvider>
        </SocketProvider>
      </NativeBaseProvider>
    </RecoilRoot>
  );
}
