import {TransfersServiceTypes} from '@bri/rotimpres-core';
import {useBaseService} from '@bri/shared-components';
import {ServiceFunctionsType} from '@bri/shared-core';

export function useTransfersService() {
  const newBaseService = useBaseService();

  const transfersServiceFunctions: ServiceFunctionsType<TransfersServiceTypes> = {
    create: params => newBaseService().auth().post('v1/transfers/create', params),
    getTransfer: params => newBaseService().auth().get('v1/transfers/getTransfer', params),
    accept: params => newBaseService().auth().post('v1/transfers/accept', params),
    reject: params => newBaseService().auth().post('v1/transfers/reject', params),
  };

  return transfersServiceFunctions;
}
