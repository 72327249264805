import {useState} from 'react';
import {deleteToken, getMessaging, getToken} from 'firebase/messaging';
import moment from 'moment';
import {IUserBase, NOTIFICATION_CHANNEL, NOTIFICATION_PLATFORM, NotificationToken} from '@bri/shared-core';
import {useRecoilState} from 'recoil';
import {COOKIES_TYPES, CookiesService, loggedUser, useAuthService} from '@bri/shared-components';
import Config from '../../config/config';
import firebaseApp from './firebase';
import {useNotificationsService} from '../../services/NotificationsService';
import {isAndroidApp} from '../appUtils';

export enum TOKEN_UPDATE_MODE {
  ENTER,
  UPDATE,
  QUIT,
}

const usePushToken = () => {
  const [token, setToken] = useState('');
  const notificationsService = useNotificationsService();
  const [userRecoil, setUserRecoil] = useRecoilState(loggedUser);
  const authService = useAuthService();

  const updateServiceWorker = async (errorLog = false) => {
    try {
      if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
        const messaging = getMessaging(firebaseApp);
        const firebaseToken = await getToken(messaging, {
          vapidKey: Config.firebaseVapidKey,
        });
      }
    } catch (error) {
      if (errorLog) {
        console.log('Error updating service worker', error);
      }
    }
  };

  // Retrieves the token from react state or firebase
  const retrieveToken = async (firstTryGetFromState = true) => {
    let currentToken = '';

    // Get token from state
    if (firstTryGetFromState) {
      currentToken = token;
      if (currentToken && currentToken != '') {
        return currentToken;
      }
    }

    // Get token from firebase (also refreshes service worker)
    const permission = await Notification.requestPermission();
    if (permission == 'granted') {
      try {
        if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
          const messaging = getMessaging(firebaseApp);
          const firebaseToken = await getToken(messaging, {
            vapidKey: Config.firebaseVapidKey,
          });
          if (firebaseToken && firebaseToken != '') {
            currentToken = firebaseToken;
            setToken(currentToken);
          }
        }
      } catch (error) {
        console.log('An error occurred while retrieving token:', error);
      }
    }

    return currentToken;
  };

  // Generates a new firebase push token.
  const renovatePushToken = async () => {
    if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
      // Remove token from firebase
      try {
        const messaging = getMessaging(firebaseApp);
        await deleteToken(messaging);
      } catch (error) {
        console.log('Error removing token from firebase: ', error);
      }
    }
    // Generate new token
    const t = await retrieveToken(false);
    return t;
  };

  // Returns an object ready to use with registerToken endpoint
  const generateRegisterToken = (t: string) => {
    const returnToken = {
      date: moment().toDate(),
      expiration: moment().add(100, 'years').toDate(),
      token: t as string,
      type: isAndroidApp ? NOTIFICATION_PLATFORM.ANDROID : NOTIFICATION_PLATFORM.IOS,
    } as NotificationToken;
    return returnToken;
  };

  // Adds, removes and/or regenerates token depending on notification permissions (external) and notification settings (rotimpres notification settings screen)
  // when forceUpdate is true, forces the update of the token on database.
  const updatePushToken = async (mode: TOKEN_UPDATE_MODE, user?: IUserBase | null, forceUpdate?: boolean) => {
    try {
      let t = await retrieveToken();

      // Removes the token from the user that logs out
      if (mode == TOKEN_UPDATE_MODE.QUIT) {
        if (t && t != '') {
          if (user) {
            notificationsService.removeToken({token: t});
          }
        }
      }

      // Generates a new push token if user enters / logs out of the app
      if (mode == TOKEN_UPDATE_MODE.ENTER || mode == TOKEN_UPDATE_MODE.QUIT) {
        t = await renovatePushToken();
      }

      // Clears all user push tokens and adds the currently active one
      if (mode == TOKEN_UPDATE_MODE.ENTER) {
        if (t && t != '') {
          if (user && user.notifications?.settings?.channels.includes(NOTIFICATION_CHANNEL.PUSH)) {
            notificationsService
              .removeAllTokens()
              .response(res => {
                notificationsService.registerToken(generateRegisterToken(t as string));
              })
              .error(err => console.log(err));
          }
        }
      }

      // Token invalid
      if (!t || t == '') {
        return false;
      }

      // Updates the status of the notifications
      if (mode == TOKEN_UPDATE_MODE.UPDATE && user) {
        const pushEnabled = user.notifications?.settings?.channels.includes(NOTIFICATION_CHANNEL.PUSH);
        const userHasToken = user.notifications?.tokens && user.notifications.tokens.length > 0 && user.notifications.tokens.some(elem => elem.token == t);

        if (pushEnabled && (!userHasToken || forceUpdate)) {
          const hasAccessToken = await CookiesService.hasKey(COOKIES_TYPES.TECHNICAL, 'access_token');
          // Add token to user
          return new Promise((resolve, reject) => {
            notificationsService
              .removeAllTokens()
              .response(res1 => {
                notificationsService
                  .registerToken(generateRegisterToken(t as string))
                  .response(res2 => {
                    if (res2.success) {
                      // Refresh user recoil
                      if (hasAccessToken) {
                        authService
                          .me()
                          .response(resp => {
                            setUserRecoil(resp.user);
                            resolve(true);
                          })
                          .error(err => reject(err));
                      }
                    }
                  })
                  .error(err => {
                    reject(err);
                  })
                  .error(err => {
                    reject(err);
                  });
              })
              .error(err => {
                reject(err);
              });
          });
        }
        if (!pushEnabled && userHasToken) {
          const hasAccessToken = await CookiesService.hasKey(COOKIES_TYPES.TECHNICAL, 'access_token');
          // Remove token from user
          return new Promise((resolve, reject) => {
            notificationsService
              .removeToken({token: t as string})
              .response(res => {
                if (res.success) {
                  // Refresh user recoil
                  if (hasAccessToken) {
                    authService
                      .me()
                      .response(resp => {
                        setUserRecoil(resp.user);
                        resolve(true);
                      })
                      .error(err => reject(err));
                  }
                }
              })
              .error(err => {
                reject(err);
              });
          });
        }
      }

      return true;
    } catch (error) {
      console.log('Error updating push token', error);
      return false;
    }
  };

  return {updatePushToken, updateServiceWorker};
};

export default usePushToken;
