import React, {FC} from 'react';
import {Box, Text, View, theme} from 'native-base';
import {useTranslation} from 'react-i18next';
import {BottomTabScreenProps} from '@react-navigation/bottom-tabs';
import {Map} from '@bri/shared-components';
import {useHeaderHeight} from '@react-navigation/elements';
import {ScreensParams} from '../navigators/screens';
import {PathsSVG} from '../utils/imagesUtils';
import Config from '../config/config';

type Props = BottomTabScreenProps<ScreensParams, 'DevolutionMap'>;

export const DevolutionMapScreen: FC<Props> = props => {
  const tag = props.route.params && props.route.params.tag;
  const {t} = useTranslation();
  const headerHeight = useHeaderHeight();

  if (!tag) {
    return <Text>{t('Product ID Invalid')}</Text>;
  }

  return (
    <View style={{position: 'absolute', top: -headerHeight, width: '100%', height: `100vh`}}>
      <Box width="100%" height="100%">
        {tag.returnPoints.length > 0 ? (
          <>
            {tag.returnPoints.length === 1 ? (
              /* Map with 1 point, specific zoom */
              <Map
                rounded="none"
                zoom={15}
                apikey={Config.googleMaps.apiKey}
                locations={tag.returnPoints?.map(returnPoint => ({
                  coords: {
                    lat: (returnPoint as any).location[0].coordinates[0],
                    lng: (returnPoint as any).location[0].coordinates[1],
                  },
                  icon: {
                    symbol: {
                      path: PathsSVG.mapIcon,
                      fillColor: theme.colors.darkBlue[600],
                      fillOpacity: 10,
                      scale: 1,
                      strokeColor: 'transparent',
                    },
                    anchor: {
                      x: 1,
                      y: 1,
                    },
                  },
                  info: {
                    title: returnPoint.name,
                    description: returnPoint.address,
                  },
                }))}
              />
            ) : (
              /* Map with multiple points, automatic zoom */
              <Map
                rounded="none"
                apikey={Config.googleMaps.apiKey}
                locations={tag.returnPoints?.map(returnPoint => ({
                  coords: {
                    lat: (returnPoint as any).location[0].coordinates[0],
                    lng: (returnPoint as any).location[0].coordinates[1],
                  },
                  icon: {
                    symbol: {
                      path: PathsSVG.mapIcon,
                      fillColor: theme.colors.darkBlue[600],
                      fillOpacity: 10,
                      scale: 1,
                      strokeColor: 'transparent',
                    },
                    anchor: {
                      x: 1,
                      y: 1,
                    },
                  },
                  info: {
                    title: returnPoint.name,
                    description: returnPoint.address,
                  },
                }))}
              />
            )}
          </>
        ) : (
          /* Fallback map with no points */
          <Map apikey={Config.googleMaps.apiKey} center={{coords: {lat: 40, lng: -4}}} zoom={3} rounded="none" />
        )}
      </Box>
    </View>
  );
};
