import React from 'react';
import {Button, ScrollView, Text, View} from 'native-base';
import {useTranslation} from 'react-i18next';
import {Linking, SafeAreaView} from 'react-native';
import AccordionItem from '../../components/FaqButton';
import CommonLayout from '../../layouts/CommonLayout';

const FrequentlyAskedQuestionsScreen = () => {
  const {t} = useTranslation();

  return (
    <CommonLayout>
      <SafeAreaView>
        <ScrollView contentInsetAdjustmentBehavior="automatic" flex={1}>
          <AccordionItem title={t('What is NFC track and how does it work?')}>
            <Text>
              {t('NFC track is a product authentication ecosystem that allows users to easily authenticate and trade products by tapping their smartphone on the item')}
            </Text>
          </AccordionItem>
          <AccordionItem title={t('How does the app verify the authenticity of a product?')}>
            <Text>
              {t(
                'When a product is equipped with an NFC chip, the chip provides a unique and secure authentication message to the smartphone protected by Blockchain system. '
              )}
            </Text>
          </AccordionItem>
          <AccordionItem title={t('How does NFC technology help in product authentication?')}>
            <Text>{t('NFC enables connectivity and electronic verification of physical products, ensuring their authenticity and preventing counterfeiting')}</Text>
          </AccordionItem>
          <AccordionItem title={t('What is an NFC?')}>
            <Text>
              {t(
                'Near-field communication (NFC) is a short-range, high-frequency wireless communication technology that allows, in this case, the exchange of data between a smartphone and a physical product.'
              )}
            </Text>
          </AccordionItem>
          <AccordionItem title={t('What role does blockchain play in product authentication?')}>
            <Text>{t('Blockchain technology is used to create a tamper-proof and immutable record of product authentication transactions')}</Text>
          </AccordionItem>
          <AccordionItem title={t('How does the app protect the authenticity and integrity of the product information?')}>
            <Text>
              {t('The app utilizes blockchain technology to ensure the security and immutability of the product information, preventing tampering or unauthorized changes')}
            </Text>
          </AccordionItem>
          <AccordionItem title={t('How does the app prevent counterfeit products?')}>
            <Text>
              {t(
                'By using NFC technology and blockchain authentication, the app verifies the authenticity of the product, making it difficult for counterfeit products to pass the verification process'
              )}
            </Text>
          </AccordionItem>
          <AccordionItem title={t('What is a NFT?')}>
            <Text>
              {t(
                'NFTs are unequivocal representations of assets, both digital and physical, on the blockchain network. In the case of our platform, the NFT delivered after scanning the barcode is a digital representation of the purchased product.'
              )}
            </Text>
          </AccordionItem>
          <AccordionItem title={t('What is an NFT of the physical product?')}>
            <Text>
              {t('An NFT (Non-Fungible Token) represents a unique digital asset that can be linked to a physical product. It provides proof of ownership and authenticity')}
            </Text>
          </AccordionItem>
          <AccordionItem title={t('How to read an NFC?')}>
            <View>
              <Text>
                {t(
                  'In current Android and iOS systems the NFC option is always active, you just have to unlock the screen and pass the back of the mobile slowly and close (1-3cm) to the tag indicated on the product. In slightly older systems it can be activated either by sliding down on your main screen, where the quick shortcuts are, or in settings and in the wireless connections and networks tab or similar. Once the NFC is activated, then you must perform the movement described above.Here is an updated list of smartphones that have an NFC reader:'
                )}
              </Text>
              <Text style={{color: 'blue'}} onPress={() => Linking.openURL('https://en.wikipedia.org/wiki/List_of_NFC-enabled_mobile_devices')}>
                https://en.wikipedia.org/wiki/List_of_NFC-enabled_mobile_devices
              </Text>
            </View>
          </AccordionItem>
          <AccordionItem title={t('What information can I access about a product using the app?')}>
            <Text>{t('The app provides various information about the product, including certificates, descriptions, instructions on how to use it, and more')}</Text>
          </AccordionItem>
          <AccordionItem title={t('Can I obtain promotions or discounts through the app?')}>
            <Text>{t('Yes, the app may offer promotions and discounts for authenticated products')}</Text>
          </AccordionItem>
          <AccordionItem title={t('Where can I see all my promotional codes?')}>
            <Text>
              {t(
                'Thanks to the central button of the application, you can access the "My Promotions" section. In this section, you will find both active and expired promotions. You can also see the promotions associated with a specific product in its card.'
              )}
            </Text>
          </AccordionItem>
          <AccordionItem title={t('How do I know what expiration date my code has?')}>
            <Text>
              {t(
                'In the "My promotions" section, accessible from the central button of the application, you can see all the promotions with their expiration date. You can also view it within the promotion card.'
              )}
            </Text>
          </AccordionItem>
          <AccordionItem title={t('Can I authenticate products from different categories such as perfumes, makeup creams, wines, and spirits?')}>
            <Text>{t('Yes, the app can be used to authenticate a wide range of physical products, including perfumes, makeup creams, wines, spirits, and more')}</Text>
          </AccordionItem>
          <AccordionItem title={t('How to return the packaging of a product?')}>
            <Text>
              {t(
                'You can access the card of your NFT in the "My Products" section. In the profile, you will see the "return map" button. In the map, you will find different locations where you can return the product packaging.'
              )}
            </Text>
          </AccordionItem>
          <AccordionItem title={t('How to manage my NFTs?')}>
            <Text>
              {t(
                'You can obtain your NFTs by clicking on the "claim" button once you have scanned the packaging. Once claimed, you will see it in the "My Products" section, and you will be able to enjoy the associated promotions.'
              )}
            </Text>
          </AccordionItem>
          <AccordionItem title={t('How to know the expiration date of my NFT?')}>
            <Text>
              {t(
                'You can see the expiration date of your NFTs in the "My Products" section. It is visible both on the screen where you can see all your tokens listed and within the card of each NFT.'
              )}
            </Text>
          </AccordionItem>
          <AccordionItem title={t('If I delete my NFC track account, can I transfer my NFTs to another wallet?')}>
            <Text>
              {t(
                'Yes, you can transfer your NFTs to another wallet. To do so, you need to access the product card and click on the "Transfer NFT" button before deleting the account.'
              )}
            </Text>
          </AccordionItem>
        </ScrollView>
      </SafeAreaView>
    </CommonLayout>
  );
};

export default FrequentlyAskedQuestionsScreen;
