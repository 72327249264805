import {BottomTabScreenProps} from '@react-navigation/bottom-tabs';
import React, {FC, useEffect, useState} from 'react';
import {IProduct, ITag} from '@bri/rotimpres-core';
import {Heading, ScrollView, Spinner, Text, View} from 'native-base';
import {useTranslation} from 'react-i18next';
import {useNavigation} from '@react-navigation/native';
import {ScreensParams} from '../navigators/screens';
import {ProductCard} from '../components/ProductCard';
import {ItemList} from '../components/ItemList';
import {useProductsService} from '../services/ProductsService';
import {ItemType} from '../components/Item';
// import {getLinkingSchema} from '../navigators/linking';

type Props = BottomTabScreenProps<ScreensParams, 'Validate'>;

export const ValidateScreen: FC<Props> = props => {
  const productsService = useProductsService();
  const [tag, setTag] = useState<ITag>();
  const [product, setProduct] = useState<IProduct>();
  const [client, setClient] = useState('');
  const [hasOwner, setHasOwner] = useState<boolean>(false);

  const tagCode = props.route.params && props.route.params.id;
  const [loading, setLoading] = useState(true);
  const {t} = useTranslation();

  useEffect(() => {
    if (tagCode) {
      productsService
        .validate({tag: String(tagCode)})
        .apiResponse(() => {
          setLoading(false);
        })
        .response(async resp => {
          setTag(resp.tag);
          setProduct(resp.product);
          setClient(resp.client);
          setHasOwner(resp.hasOwner);
        })
        .error(err => console.log(err))
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [tagCode]);

  if (loading) {
    return <Spinner size="lg" mt={3} />;
  }
  if (!product) {
    return <ProductCard client={client} product={product} mine={false} valid={false} tag={tag} />;
  }

  if (!tag || !product) {
    return <Text>{t('Product ID Invalid')}</Text>;
  }

  return (
    <ScrollView showsVerticalScrollIndicator={false}>
      <View mt={5}>
        {/* <Image height={35} resizeMode="cover" source={getResource(Images.background)} mb={2} /> */}
        <Heading fontSize="4xl" alignSelf="center">
          {client}
        </Heading>
        <ProductCard client={client} product={product} mine={false} hasOwner={hasOwner} tag={tag} />
        <ItemList title={t('Promotions included') as string} data={tag?.promotions as any} type={ItemType.Promotion} />
      </View>
    </ScrollView>
  );
};
