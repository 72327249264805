import React, {createContext, useState} from 'react';
import PopUp from './PopUp';

type ButtonData = {
  label: string;
  onPress: () => void;
  variant?: string;
};

type PopUpContextType = {
  showPopUp: (title: string, content: React.ReactNode, buttons: ButtonData[], closeOnOverlayClick?: boolean, isError?: boolean) => void;
  hidePopUp: () => void;
};

type Props = {
  children: React.ReactNode;
};

export const PopUpContext = createContext<PopUpContextType | undefined>(undefined);

export const PopUpProvider: React.FC<Props> = ({children}) => {
  const [isPopUpVisible, setPopUpVisible] = useState(false);
  const [popUpData, setPopUpData] = useState<{
    title: string;
    content: React.ReactNode;
    buttons: ButtonData[];
    closeOnOverlayClick?: boolean;
    isError?: boolean;
  }>({
    title: '',
    content: '',
    buttons: [],
    closeOnOverlayClick: true,
    isError: false,
  });

  const showPopUp = (title: string, content: React.ReactNode, buttons: ButtonData[], closeOnOverlayClick?: boolean, isError?: boolean) => {
    setPopUpData({title, content, buttons, closeOnOverlayClick, isError});
    setPopUpVisible(true);
  };

  const hidePopUp = () => {
    setPopUpVisible(false);
  };

  return (
    <PopUpContext.Provider value={{showPopUp, hidePopUp}}>
      {children}
      {isPopUpVisible && (
        <PopUp
          title={popUpData.title}
          content={popUpData.content}
          buttons={popUpData.buttons}
          visible={isPopUpVisible}
          onClose={hidePopUp}
          closeOnOverlayClick={popUpData.closeOnOverlayClick}
          isError={popUpData.isError}
        />
      )}
    </PopUpContext.Provider>
  );
};
