import {BottomTabScreenProps} from '@react-navigation/bottom-tabs';
import React, {FC, useContext, useEffect, useState} from 'react';
import {Box, Button, FormControl, Icon, Input, ScrollView, Select, Stack, View, VStack, Text, HStack} from 'native-base';
import {Trans, useTranslation} from 'react-i18next';
import InputMask from 'react-input-mask';
import {ConfirmationDialogContext, COOKIES_TYPES, CookiesService, CountrySelector, loggedUser, TOAST_STATUS, TOAST_VARIANT, useSharedToast} from '@bri/shared-components';
import {FontAwesome, AntDesign} from '@native-base/icons';
import {Controller, Message, SubmitHandler, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import moment from 'moment';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {AVAILABLE_LANGUAGES, AVAILABLE_LANGUAGES_TYPE, DEFAULT_LANGUAGE, UpdateUserDTO} from '@bri/rotimpres-core';
import {Link, useIsFocused, useNavigation} from '@react-navigation/native';
import {ScreensParams} from '../navigators/screens';
import {useUsersService} from '../services/UsersService';
import {changeLanguage} from '../utils/languageUtils';
import usePushToken, {TOKEN_UPDATE_MODE} from '../utils/firebase/usePushToken';

type Props = BottomTabScreenProps<ScreensParams, 'ProfileScreen'>;

type Inputs = {
  name: string;
  surname: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  phone: string;
  country: string;
  birthDate: string;
  language: AVAILABLE_LANGUAGES_TYPE;
  gender: string;
};

const today = new Date();
const minDate = moment().subtract(100, 'years');

function parseDateString(value: any, originalValue: any) {
  return moment(originalValue, 'DD/MM/YYYY').toDate();
}

export const ProfileScreen: FC<Props> = props => {
  const {t} = useTranslation();
  const userService = useUsersService();
  const user = useRecoilValue(loggedUser);
  const setUser = useSetRecoilState(loggedUser);
  const [passwordInputType, setPasswordInputType] = useState<'password' | 'text' | undefined>('password');
  const [passwordConfirmationInputType, setPasswordConfirmationInputType] = useState<'password' | 'text' | undefined>('password');
  const [passwordIcon, setPasswordIcon] = useState('eye-slash');
  const [passwordConfirmationIcon, setPasswordConfirmationIcon] = useState('eye-slash');
  const sharedToast = useSharedToast();
  const [isLoading, setIsLoading] = useState(false);
  const navigation = useNavigation<any>();
  const deleteUserDialog = useContext(ConfirmationDialogContext);
  const usersService = useUsersService();
  const {updatePushToken} = usePushToken();

  const ProfileSchema = Yup.object().shape({
    name: Yup.string().required(t('Required')!),
    surname: Yup.string().required(t('Required')!),
    email: Yup.string()
      .email()
      .required(t('Required') as Message),
    password: Yup.string().test('password-min-length', t('Password must be at least 8 characters long')!, value => !value || value.length >= 8),
    passwordConfirmation: Yup.string().test('password-match', t('Passwords must match')!, (value, options) => {
      const passwordFieldValue = options.parent.password;
      return !passwordFieldValue || value === passwordFieldValue;
    }),
    phone: Yup.string()
      .test('telephone-min-length', t('Phone number must have exactly 9 digits')!, value => {
        if (!value) return true;
        return value.length === 9;
      })
      .optional(),
    country: Yup.string().optional(),
    birthDate: Yup.date()
      .typeError(t('Invalid format date')!)
      .transform(parseDateString)
      .max(today, t("Birthdate can't be future")!)
      .min(minDate, t("Birthdate can't be older than 100 years")!)
      .required(t('Required')!),
    language: Yup.string().optional(),
    gender: Yup.string().optional(),
  });

  const {
    control,
    formState: {errors},
    handleSubmit,
    setValue,
    trigger,
  } = useForm<Inputs>({
    resolver: yupResolver(ProfileSchema),
  });

  const isFocused = useIsFocused();

  useEffect(() => {
    const updateFormValues = () => {
      setValue('name', user?.name ?? '');
      setValue('surname', user?.familyName ?? '');
      setValue('email', user?.email ?? '');
      setValue('phone', user?.telephone ?? '');
      setValue('country', user?.address?.addressCountry ?? '');
      setValue('birthDate', user ? moment(user.birthDate).format('DD/MM/YYYY') : '');
      setValue('gender', user?.gender ?? '');
      setValue('language', (user?.language ?? DEFAULT_LANGUAGE) as AVAILABLE_LANGUAGES_TYPE);
    };
    updateFormValues();
    trigger();
  }, [user, isFocused]);

  const removeCookies = async () => {
    await CookiesService.removeKey(COOKIES_TYPES.TECHNICAL, 'access_token');
  };

  const handleLogout = async () => {
    try {
      await updatePushToken(TOKEN_UPDATE_MODE.QUIT, user);
      await removeCookies();
      setUser(null);
      changeLanguage(DEFAULT_LANGUAGE);
      navigation.navigate('Home');
    } catch (error) {
      await updatePushToken(TOKEN_UPDATE_MODE.UPDATE, user);
      sharedToast({
        title: t('Something Went Wrong'),
        description: t('Error Logout'),
        status: TOAST_STATUS.ERROR,
        variant: TOAST_VARIANT.SUBTLE,
      });
    }
  };

  const handleDeleteAccount = async () => {
    try {
      const resp = await usersService.removeSelf();
      if (resp) {
        await updatePushToken(TOKEN_UPDATE_MODE.QUIT, user);
        await removeCookies();
        setUser(null);
        changeLanguage(DEFAULT_LANGUAGE);
        sharedToast({
          title: t('Account removed'),
          description: t('The account was removed successfully'),
          status: TOAST_STATUS.ERROR,
          variant: TOAST_VARIANT.SUBTLE,
        });
      }
    } catch (error) {
      await updatePushToken(TOKEN_UPDATE_MODE.UPDATE, user);
      console.error(error);
      sharedToast({
        title: t('Error on remove account'),
        description: t('Something went wrong trying to remove the account'),
        status: TOAST_STATUS.ERROR,
        variant: TOAST_VARIANT.SUBTLE,
      });
    } finally {
      deleteUserDialog?.hideConfirmationDialog();
    }
  };

  const submitForm: SubmitHandler<Inputs> = data => {
    setIsLoading(true);
    const userUpdate: UpdateUserDTO = {
      ...data,
      _id: user?._id || '',
      birthDate: moment(data.birthDate).format('DD/MM/YYYY'),
    };
    userService
      .update(userUpdate)
      .response(updateResp => {
        sharedToast({
          title: t('Profile successfully updated'),
          description: t(''),
          status: TOAST_STATUS.SUCCESS,
          variant: TOAST_VARIANT.SUBTLE,
        });
        setUser(updateResp.user);
        changeLanguage(updateResp.user.language);
        setIsLoading(false);
      })
      .error(err => sharedToast({title: t('Something Went Wrong'), description: err.type as string, status: TOAST_STATUS.ERROR, variant: TOAST_VARIANT.SUBTLE}))
      .finally(() => setIsLoading(false));
  };

  const handlePassword = () => {
    if (passwordInputType === 'text') {
      setPasswordInputType('password');
      setPasswordIcon('eye');
    } else {
      setPasswordInputType('text');
      setPasswordIcon('eye-slash');
    }
  };

  const handlePasswordConfirmation = () => {
    if (passwordConfirmationInputType === 'text') {
      setPasswordConfirmationInputType('password');
      setPasswordConfirmationIcon('eye');
    } else {
      setPasswordConfirmationInputType('text');
      setPasswordConfirmationIcon('eye-slash');
    }
  };
  const handleNotificationSettings = () => {
    navigation.navigate('NotificationSettings');
  };

  return (
    <ScrollView showsVerticalScrollIndicator={false} backgroundColor="white" px={5} pb={5}>
      <View>
        <View px={2}>
          <VStack space={4}>
            <FormControl isRequired isInvalid={'name' in errors} key="name" flex={1}>
              <FormControl.Label _text={{bold: true, color: 'black'}} variant="register">
                {t('Name')}
              </FormControl.Label>
              <Controller name="name" control={control} render={({field}) => <Input type="text" variant="register" color="black" placeholder={t('Name')!} {...field} />} />
              {errors.name && <FormControl.ErrorMessage>{errors.name?.message}</FormControl.ErrorMessage>}
            </FormControl>

            <FormControl isRequired isInvalid={'surname' in errors} key="surname" flex={1}>
              <FormControl.Label _text={{bold: true, color: 'black'}} variant="register">
                {t('Surname')}
              </FormControl.Label>
              <Controller
                name="surname"
                control={control}
                render={({field}) => <Input type="text" variant="register" color="black" placeholder={t('Surname')!} {...field} />}
              />
              {errors.surname && <FormControl.ErrorMessage>{errors.surname?.message}</FormControl.ErrorMessage>}
            </FormControl>

            <FormControl isRequired isInvalid={'email' in errors} key="email" flex={1}>
              <FormControl.Label _text={{bold: true, color: 'black'}} variant="register">
                {t('Email')}
              </FormControl.Label>
              <Controller
                name="email"
                control={control}
                render={({field}) => <Input type="text" keyboardType="email-address" variant="register" color="black" placeholder={t('Email')!} {...field} />}
              />
              {errors.email && <FormControl.ErrorMessage>{errors.email?.message}</FormControl.ErrorMessage>}
            </FormControl>

            <FormControl isRequired isInvalid={'password' in errors} key="password" flex={1}>
              <FormControl.Label _text={{bold: true, color: 'black'}} variant="register">
                {t('Password')}
              </FormControl.Label>
              <Controller
                name="password"
                control={control}
                render={({field}) => (
                  <Input
                    type={passwordInputType}
                    color="black"
                    variant="register"
                    InputRightElement={
                      <Icon
                        as={FontAwesome}
                        name={passwordIcon}
                        size={5}
                        mr={2}
                        color="black"
                        onPress={() => {
                          handlePassword();
                        }}
                      />
                    }
                    pl={2}
                    {...field}
                  />
                )}
              />
              {errors.password && <FormControl.ErrorMessage>{errors.password?.message}</FormControl.ErrorMessage>}
            </FormControl>

            <FormControl isRequired isInvalid={'passwordConfirmation' in errors} key="passwordConfirmation" flex={1}>
              <FormControl.Label _text={{bold: true, color: 'black'}} variant="register">
                {t('Repeat Password')}
              </FormControl.Label>
              <Controller
                name="passwordConfirmation"
                control={control}
                render={({field}) => (
                  <Input
                    type={passwordConfirmationInputType}
                    color="black"
                    variant="register"
                    InputRightElement={
                      <Icon
                        as={FontAwesome}
                        name={passwordConfirmationIcon}
                        size={5}
                        mr={2}
                        color="black"
                        onPress={() => {
                          handlePasswordConfirmation();
                        }}
                      />
                    }
                    {...field}
                  />
                )}
              />
              {errors.passwordConfirmation && <FormControl.ErrorMessage>{errors.passwordConfirmation.message}</FormControl.ErrorMessage>}
            </FormControl>

            <FormControl isInvalid={'phone' in errors} key="phone" flex={1}>
              <FormControl.Label _text={{bold: true, color: 'black'}} variant="register">
                {t('Phone')}
              </FormControl.Label>
              <Controller
                name="phone"
                control={control}
                render={({field}) => <Input type="text" keyboardType="phone-pad" variant="register" color="black" placeholder={t('Phone')!} {...field} />}
              />
              {errors.phone && <FormControl.ErrorMessage>{errors.phone?.message}</FormControl.ErrorMessage>}
            </FormControl>

            <FormControl isRequired isInvalid={'country' in errors} key="country" flex={1}>
              <FormControl.Label _text={{bold: true, color: 'black'}} variant="register">
                {t('Country')}
              </FormControl.Label>
              <Controller
                name="country"
                control={control}
                render={({field}) => (
                  <Box borderColor="muted.300" borderWidth={1} borderRadius={5}>
                    <CountrySelector
                      selectedValue={field.value ?? user?.address?.addressCountry}
                      color="black"
                      {...(field as any)}
                      onValueChange={(itemValue: string) => setValue('country', itemValue)}
                    />
                  </Box>
                )}
              />
              {errors.country && <FormControl.ErrorMessage>{errors.country.message}</FormControl.ErrorMessage>}
            </FormControl>

            <FormControl isRequired isInvalid={'birthDate' in errors} key="birthDate" flex={1}>
              <FormControl.Label _text={{bold: true, color: 'black'}} variant="register">
                {t('Birth date')}
              </FormControl.Label>
              <Controller
                name="birthDate"
                control={control}
                render={({field}) => (
                  <InputMask {...field} mask="99/99/9999">
                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                    {/* @ts-ignore */}
                    {(inputProps: any) => <Input type="text" variant="register" color="black" placeholder={t('dd/mm/yyyy')!} {...field} />}
                  </InputMask>
                )}
              />
              {errors.birthDate && <FormControl.ErrorMessage>{errors.birthDate.message}</FormControl.ErrorMessage>}
            </FormControl>

            <FormControl isInvalid={'gender' in errors} key="gender" flex={1}>
              <FormControl.Label _text={{bold: true, color: 'black'}} variant="register">
                {t('Gender')}
              </FormControl.Label>
              <Controller
                name="gender"
                control={control}
                render={({field}) => (
                  <Box borderColor="muted.300" borderWidth={1} borderRadius={5}>
                    <Select selectedValue={field.value ?? 'gender'} color="black" {...(field as any)} onValueChange={itemValue => setValue('gender', itemValue)}>
                      {[
                        {title: t('Male'), value: 'm'},
                        {title: t('Female'), value: 'f'},
                        {title: t('No binary'), value: 'nb'},
                        {title: t('Other'), value: 'other'},
                        {title: t('I prefer not to answer'), value: 'nc'},
                      ].map(item => (
                        <Select.Item key={item.value} label={item.title} value={item.value} variant="register" />
                      ))}
                    </Select>
                  </Box>
                )}
              />
              {errors.gender && <FormControl.ErrorMessage>{errors.gender.message}</FormControl.ErrorMessage>}
            </FormControl>

            <FormControl isInvalid={'language' in errors} key="language" flex={1}>
              <FormControl.Label _text={{bold: true, color: 'black'}}>{t('Language')}</FormControl.Label>
              <Controller
                name="language"
                control={control}
                render={({field}) => (
                  <Box borderColor="muted.300" borderWidth={1} borderRadius={5}>
                    <Select
                      selectedValue={field.value ?? 'language'}
                      color="black"
                      {...(field as any)}
                      onValueChange={(itemValue: AVAILABLE_LANGUAGES_TYPE) => setValue('language', itemValue)}>
                      {AVAILABLE_LANGUAGES.map(item => (
                        <Select.Item key={item} label={t(`lang_${item}`)} value={item} variant="register" />
                      ))}
                    </Select>
                  </Box>
                )}
              />
              {errors.language && <FormControl.ErrorMessage>{errors.language.message}</FormControl.ErrorMessage>}
            </FormControl>

            <HStack pt={3} space={4} justifyContent="center">
              <Text color="primary.500" textDecoration="underline">
                <Link to={{screen: 'PrivacyPolicy'}} style={{borderBottomWidth: 1, borderBottomColor: 'white', textDecorationLine: 'underline'}}>
                  {t('Privacy policy')}
                </Link>
              </Text>

              <Text color="primary.500" textDecoration="underline">
                <Link to={{screen: 'Terms'}} style={{borderBottomWidth: 1, borderBottomColor: 'white', textDecorationLine: 'underline'}}>
                  {t('Terms of use')}
                </Link>
              </Text>
            </HStack>

            <HStack space={3} justifyContent="flex-end">
              <HStack space={3}>
                <Button
                  w="fit-content"
                  shadow="2"
                  style={{marginTop: 20}}
                  alignSelf="center"
                  px={4}
                  colorScheme="white"
                  variant="outline"
                  borderColor="primary.500"
                  _text={{fontWeight: 'bold', color: 'primary.500'}}
                  leftIcon={<Icon as={AntDesign} name="logout" color="primary.500" />}
                  onPress={handleLogout}>
                  {t('Log Out')}
                </Button>

                <Button
                  onPress={handleSubmit(submitForm)}
                  w="fit-content"
                  shadow="2"
                  style={{marginTop: 20}}
                  alignSelf="center"
                  px={6}
                  bgColor="primary.500"
                  _text={{color: 'muted.50'}}
                  isLoading={isLoading}
                  isLoadingText={t('Submitting')!}>
                  {t('Save')}
                </Button>
              </HStack>
            </HStack>

            <Stack direction="row" justifyContent="flex-end" space={3}>
              <Button
                w="fit-content"
                shadow="2"
                style={{marginTop: 20}}
                alignSelf="center"
                px={4}
                bgColor="error.500"
                _text={{color: 'white'}}
                leftIcon={<Icon as={AntDesign} name="delete" color="white" />}
                isLoadingText={t('Submitting')!}
                onPress={() => {
                  deleteUserDialog?.showConfirmationDialog(
                    t('Delete Account'),
                    <View>
                      <Text>{t('Are you sure you want to delete your account permanently? This operation cannot be undone')}</Text>
                    </View>,
                    {
                      label: t('Delete') as string,
                      onPress: handleDeleteAccount,
                    }
                  );
                }}>
                {t('Delete Account')}
              </Button>
            </Stack>
          </VStack>
        </View>
      </View>
    </ScrollView>
  );
};
