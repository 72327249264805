import {useNavigation} from '@react-navigation/core';
import moment from 'moment';
import {VStack, Text, useTheme, HStack} from 'native-base';
import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {TouchableOpacity} from 'react-native';
import {ITransfer} from '@bri/rotimpres-core';
import {useTransfersService} from '../services/TransferService';

type Props = {
  description: string;
  date?: Date;
  button?: string | null;
  transferId?: string | null;
};

export const NotificationTarget: FC<Props> = ({description, date, button, transferId}) => {
  const theme = useTheme();
  const {t} = useTranslation();
  const navigation = useNavigation<any>();
  const transfersService = useTransfersService();
  const [transfer, setTransfer] = useState<ITransfer | null>(null);

  function formatDateAndTime(time: Date) {
    const formattedTime = moment(time).format('DD/MM/YY - HH:mm');
    return formattedTime;
  }

  const loadTransferRequest = () =>
    new Promise<ITransfer | null>((resolve, reject) => {
      transfersService
        .getTransfer({id: transferId as string})
        .response(resp => resolve(resp.transfer))
        .error(err => reject(err));
    });

  const navigateToTransferRequest = async () => {
    if (!transferId) {
      return;
    }

    let transferRequest = transfer;

    if (!transferRequest) {
      transferRequest = await loadTransferRequest();
      setTransfer(transferRequest);
    }

    navigation.navigate('Product', {id: transferRequest?.tagId.toString(), transfer: transferRequest?._id});
  };

  return (
    <VStack backgroundColor={theme.colors.secondary[500]} borderRadius={5} paddingX={5} paddingTop={5}
shadow={5} marginY={1}>
      <HStack alignItems="center" justifyContent="space-between">
        <Text textAlign="inherit" fontSize="sm" pb={2} overflow="hidden">
          {description}
        </Text>
        {button && (
          <TouchableOpacity onPress={() => navigateToTransferRequest()}>
            <Text paddingLeft={5} bold color={theme.colors.primary[500]} lineBreakMode="head">
              {t(button)}
            </Text>
          </TouchableOpacity>
        )}
      </HStack>
      <HStack justifyContent="flex-end">{date && <Text fontSize="xs">{formatDateAndTime(date)}</Text>}</HStack>
    </VStack>
  );
};
