import React, {useEffect} from 'react';
import {Badge, HStack, IconButton, View, useTheme, Text} from 'native-base';
import {useTranslation} from 'react-i18next';
import {createBottomTabNavigator, BottomTabNavigationOptions} from '@react-navigation/bottom-tabs';
import {FontAwesome} from '@native-base/icons';
import {useNavigation} from '@react-navigation/core';
import {createStackNavigator, StackNavigationOptions} from '@react-navigation/stack';
import {useRecoilState, useRecoilValue} from 'recoil';
import {loggedUser} from '@bri/shared-components';
import {ScreensParams} from './screens';
import {ProductsListScreen} from '../screens/ProductsListScreen';
import {PromotionsListScreen} from '../screens/PromotionsListScreen';
import {ProfileScreen} from '../screens/ProfileScreen';
import {ProductScreen} from '../screens/ProductScreen';
import {PathsSVG} from '../utils/imagesUtils';
import SvgIcons from '../components/svgIcons';
import {DevolutionMapScreen} from '../screens/DevolutionMapScreen';
import {currentScreenNameRecoil} from '../recoil/atoms/CurrentScreenNameAtom';
import {countNewNotificationsRecoil} from '../recoil/atoms/countNewNotifications';
import {useUsersService} from '../services/UsersService';
import {PromotionScreen} from '../screens/PromotionScreen';

const Tab = createBottomTabNavigator<ScreensParams>();
const Stack = createStackNavigator<ScreensParams>();

const HeaderRightJSX = () => {
  const navigation = useNavigation<any>();
  const [newNotifications] = useRecoilState(countNewNotificationsRecoil);
  return (
    <HStack>
      <IconButton _icon={{as: FontAwesome, name: 'bell', color: 'white'}} onPress={() => navigation.navigate('Notification')} marginRight={3}>
        {newNotifications ? (
          <Badge colorScheme="red" borderRadius="full" boxSize={6} padding={0} marginLeft={3} bottom={4} focusable={false}>
            <Text bold padding={1} color="red.900">
              {newNotifications}
            </Text>
          </Badge>
        ) : (
          <></>
        )}
      </IconButton>
      <IconButton _icon={{as: FontAwesome, name: 'envelope', color: 'white', marginRight: 3}} onPress={() => navigation.navigate('Contact')} />
    </HStack>
  );
};

const HeaderStyle = () => {
  const theme = useTheme();
  const headerStyle = {
    headerStyle: {backgroundColor: theme.colors.primary['500'], borderBottomRightRadius: 20, borderBottomLeftRadius: 20, borderBottomWidth: 0},
    headerTitleAlign: 'center',
    headerTitleStyle: {color: 'white', fontSize: 22},
    headerTintColor: 'white',
    headerRight: HeaderRightJSX,
  } as StackNavigationOptions;
  return headerStyle;
};

const TabBarIcon = (focused: boolean, icon: any) => {
  const theme = useTheme();
  return (
    <View bg={focused ? 'white' : 'transparent'} paddingX={6} paddingY={1} borderRadius="16px">
      <SvgIcons path={icon} color={focused ? theme.colors.primary['500'] : 'white'} style={{width: 24, height: 24}} />
    </View>
  );
};

const TabNavigator = () => {
  const {t} = useTranslation();
  const theme = useTheme();
  const [currentScreenName] = useRecoilState(currentScreenNameRecoil);
  const userService = useUsersService();
  const user = useRecoilValue(loggedUser);
  const [, setNewNotifications] = useRecoilState(countNewNotificationsRecoil);

  useEffect(() => {
    userService
      .newNotifications({id: user?._id || ''})
      .apiResponse(() => {
        // if necessary do something here
      })
      .response(async resp => {
        setNewNotifications(resp.countNewNotifications);
      })
      .error(err => console.log(err));
    // .finally(() => console.log(err));
  }, []);

  const tabBarStyle: BottomTabNavigationOptions = {
    tabBarStyle: {
      backgroundColor: theme.colors.primary['500'],
      left: 0,
      borderTopRightRadius: 20,
      borderTopLeftRadius: 20,
      borderTopWidth: 0,
      height: '76px',
      position: currentScreenName === 'DevolutionMap' ? 'absolute' : 'relative',
    },
    tabBarLabelStyle: {display: 'none'},
    headerStyle: {backgroundColor: theme.colors.primary['500'], borderBottomRightRadius: 20, borderBottomLeftRadius: 20},
    headerTitleAlign: 'center',
    headerTitleStyle: {color: 'white', fontSize: 22},
    headerTintColor: 'white',
    headerRight: HeaderRightJSX,
  };

  return (
    <Tab.Navigator screenOptions={{...tabBarStyle}}>
      <Tab.Screen
        name="ProductListScreen"
        component={ProductsListStackScreen}
        options={{
          headerShown: false,
          tabBarIcon: ({focused}) => TabBarIcon(focused, PathsSVG.tokenIcon),
        }}
      />
      <Tab.Screen
        name="PromotionListScreen"
        component={PromotionsListStackScreen}
        options={{
          headerShown: false,
          tabBarIcon: ({focused}) => TabBarIcon(focused, PathsSVG.promotionsIcon),
        }}
      />
      <Tab.Screen
        name="ProfileScreen"
        component={ProfileScreen}
        options={{
          headerTitle: t('Profile') as string,
          tabBarIcon: ({focused}) => TabBarIcon(focused, PathsSVG.perfilIcon),
        }}
      />
    </Tab.Navigator>
  );
};

export const ProductsListStackScreen = () => {
  const {t} = useTranslation();

  return (
    <Stack.Navigator>
      <Stack.Screen name="ProductListScreen" component={ProductsListScreen} options={{...HeaderStyle(), title: t('My Tokens') as string}} />
      <Stack.Screen name="Product" component={ProductScreen} options={{...HeaderStyle(), title: t('Product') as string}} />
      <Stack.Screen name="Promotion" component={PromotionScreen} options={{...HeaderStyle(), title: t('Promotion') as string}} />
      <Stack.Screen name="DevolutionMap" component={DevolutionMapScreen} options={{...HeaderStyle(), title: t('Devolution Map') as string}} />
    </Stack.Navigator>
  );
};

export const PromotionsListStackScreen = () => {
  const {t} = useTranslation();

  return (
    <Stack.Navigator>
      <Stack.Screen name="PromotionListScreen" component={PromotionsListScreen} options={{...HeaderStyle(), title: t('My Promotions') as string}} />
      <Stack.Screen name="Promotion" component={PromotionScreen} options={{...HeaderStyle(), title: t('Promotion') as string}} />
    </Stack.Navigator>
  );
};

export default TabNavigator;
