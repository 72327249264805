import {ProductServiceTypes} from '@bri/rotimpres-core';
import {useBaseService} from '@bri/shared-components';
import {ServiceFunctionsType} from '@bri/shared-core';

export function useProductsService() {
  const newBaseService = useBaseService();

  const productsServiceFunctions: ServiceFunctionsType<ProductServiceTypes> = {
    save: params => newBaseService().auth().post('v1/products/save', params),
    getByTag: params => newBaseService().auth().get('v1/products/getByTag', params),
    getDTO: params => newBaseService().auth().get('v1/products/getDTO', params),
    getDTOEdit: params => newBaseService().auth().get('v1/products/getDTOEdit', params),
    list: params => newBaseService().auth().get('v1/products/list', params),
    listDefaultCertificates: params => newBaseService().auth().get('v1/products/listDefaultCertificates', params),
    validate: params => newBaseService().get('v1/products/validate', params),
    transferTagToUser: params => newBaseService().auth().post('v1/products/transferTagToUser', params),
    transferTagNewOwner: params => newBaseService().auth().post('v1/products/transferTagNewOwner', params),
    requestTransfer: params => newBaseService().auth().post('v1/products/requestTransfer', params),
    listUserTags: params => newBaseService().auth().get('v1/products/listUserTags', params),
    getPreview: params => newBaseService().get('v1/products/getPreview', params),
    savePreview: params => newBaseService().post('v1/products/savePreview', params),
    savePreviewEdit: params => newBaseService().auth().postFile('v1/products/savePreviewEdit', params, ['promoImages']),
    existingTags: params => newBaseService().auth().get('v1/products/existingTags', params),
    updateTags: params => newBaseService().auth().postFile('v1/products/updateTags', params, ['promoImages']),
    listUserPromotions: params => newBaseService().auth().get('v1/products/listUserPromotions', params),
  };

  return productsServiceFunctions;
}
