import React from 'react';
import {Button, Modal, Text, HStack, useTheme, Box} from 'native-base';

type Props = {
  title?: string;
  content?: React.ReactNode;
  buttons?: {label: string; onPress: () => void; variant?: string}[];
  visible?: boolean;
  isError?: boolean;
  onClose: () => void;
  closeOnOverlayClick?: boolean;
};

const PopUp: React.FC<Props> = ({title, content, buttons = [], visible, onClose, closeOnOverlayClick = true, isError = false}) => {
  const theme = useTheme();

  return (
    <Modal isOpen={visible} onClose={onClose} size="md" closeOnOverlayClick={closeOnOverlayClick} focusable={false}>
      <Modal.Content>
        <Text fontWeight="bold" color={isError ? theme.colors.danger['500'] : theme.colors.primary['500']} fontSize="xl" mt={4} mx={3}>
          {title}
        </Text>
        <Box mx={4} my={6}>
          {content}
        </Box>
        <Box px={4} py={2} alignItems="flex-end">
          <HStack space={2}>
            {buttons.map((button, index) => (
              <Button
                key={index}
                onPress={button.onPress}
                variant={button.variant}
                focusable={false}
                style={{borderWidth: 1.5, borderColor: theme.colors.primary['500'], paddingHorizontal: 20}}>
                {button.label}
              </Button>
            ))}
          </HStack>
          <Box my={2} />
        </Box>
      </Modal.Content>
    </Modal>
  );
};

export default PopUp;
