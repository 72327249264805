import React, {FC, useState} from 'react';
import {ScrollView} from 'native-base';
import {BottomTabScreenProps} from '@react-navigation/bottom-tabs';
import {COOKIES_TYPES, CookiesService, loggedUser, NotificationSettings, useAuthService} from '@bri/shared-components';
import {NOTIFICATION_CATEGORY, NOTIFICATION_CHANNEL, NOTIFICATION_PLATFORM} from '@bri/shared-core';
import {useRecoilState} from 'recoil';
import {ScreensParams} from '../navigators/screens';
import {useUsersService} from '../services/UsersService';
import {useNotificationsService} from '../services/NotificationsService';
import usePushToken, {TOKEN_UPDATE_MODE} from '../utils/firebase/usePushToken';

type Props = BottomTabScreenProps<ScreensParams, 'NotificationSettings'>;

export const NotificationSettingsScreen: FC<Props> = props => {
  const authService = useAuthService();
  const userService = useUsersService();
  const notificationsService = useNotificationsService();
  const [user, setUser] = useRecoilState(loggedUser);
  const [channels, setChannels] = useState<NOTIFICATION_CHANNEL[]>(user?.notifications?.settings?.channels as NOTIFICATION_CHANNEL[]);
  const [categories, setCategories] = useState<NOTIFICATION_CATEGORY[]>(user?.notifications?.settings?.categories as NOTIFICATION_CATEGORY[]);
  const channelsEnabled: NOTIFICATION_CHANNEL[] = [NOTIFICATION_CHANNEL.IN_APP, NOTIFICATION_CHANNEL.PUSH];
  const isAuthenticated = !!user;
  const {updatePushToken} = usePushToken();

  const handleOnNotificationChange = async (notifications: any) => {
    if (isAuthenticated) {
      userService
        .updateNotifications({channels: notifications.channels, categories: notifications.categories, userId: user?._id})
        .response(async response => {
          setChannels(notifications.channels);
          setCategories(notifications.categories);

          if (await CookiesService.hasKey(COOKIES_TYPES.TECHNICAL, 'access_token')) {
            authService
              .me()
              .response(resp => {
                setUser(resp.user);
                updatePushToken(TOKEN_UPDATE_MODE.UPDATE, resp.user);
              })
              .error(err => console.log(err));
          }
        })
        .error(err => console.log(err));
    }
  };

  return (
    <ScrollView>
      <NotificationSettings channels={channels} categories={categories} channelsEnabled={channelsEnabled} onNotificationsChange={handleOnNotificationChange} />
    </ScrollView>
  );
};
