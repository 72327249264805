import {Platform} from 'react-native';
import Constants, {ExecutionEnvironment} from 'expo-constants';
import * as Device from 'expo-device';

// Saber si es Webapp o app nativa
export const isWebApp = Platform.OS === 'web';
export const isiOSApp = Platform.OS === 'ios';
export const isAndroidApp = Platform.OS === 'android';
export const isApp = isiOSApp || isAndroidApp;
// Saber el SO del dispositivo
export const isiOS = Device.osName === 'iOS' || Device.osName === 'iPadOS' || Device.osName === 'Mac OS';
export const isAndroid = !isiOS;
export const isExpo = Constants.appOwnership === 'expo';
export const deviceManufacturer = Device.manufacturer ? Device.manufacturer : 'unknown';
export const deviceName = Device.modelName ? Device.modelName : 'unknown';
export const isExpoGo = Constants.executionEnvironment === ExecutionEnvironment.StoreClient;
export const deviceYearClass = Device.deviceYearClass || 0;
