import React, {FC, useEffect, useState} from 'react';
import {COOKIES_TYPES, CookiesService, loggedUser, RecoverEmail, useAuthService, useConfig} from '@bri/shared-components';
import {useSetRecoilState} from 'recoil';
import {BottomTabScreenProps} from '@react-navigation/bottom-tabs';
import {Button, FormControl, Heading, Icon, Image, Input, Link, VStack, Text, ScrollView, Stack} from 'native-base';
import {useTranslation} from 'react-i18next';
import {ScreensParams} from '../navigators/screens';
import {Images, getResource} from '../utils/imagesUtils';

type Props = BottomTabScreenProps<ScreensParams, 'RecoverEmailClient'>;

export const RecoverEmailClient: FC<Props> = props => {
  const authService = useAuthService();
  const setUser = useSetRecoilState(loggedUser);
  const [loading, setLoading] = useState(true);
  const [config] = useConfig();
  const {t} = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      if (await CookiesService.hasKey(COOKIES_TYPES.TECHNICAL, 'access_token')) {
        authService
          .me()
          .apiResponse(() => {
            setLoading(false);
          })
          .response(resp => {
            setUser(resp.user);
          })
          .error(err => console.log(err));
      } else {
        setLoading(false);
      }
    };
    fetchData().catch(console.error);
  }, []);

  if (loading) return null;

  if (!config.oauth || !config.oauth.clientId || !config.oauth.clientSecret) {
    return null;
  }

  return (
    <ScrollView
      flex={1}
      align-items="center"
      contentContainerStyle={{flexGrow: 1, justifyContent: 'center'}}
      showsVerticalScrollIndicator={false}
      bgColor="primary.500"
      px={10}
      pb={8}>
      <VStack space={4} flex={1} justifyContent="center" bgColor="red">
        <Image alignSelf="center" justifyContent="center" source={getResource(Images.logo)} alt={t('Icon text').toString()} size="lg" width={195} height={195} />
        <Stack flex={1} mt={4} marginTop="20%">
          <RecoverEmail view="client" />
        </Stack>
        <VStack alignItems="center" justifyContent="flex-end" mt={4}>
          <Image source={getResource(Images.logo_footer)} alt={t('Icon text').toString()} width="115px" height="40px" />
        </VStack>
      </VStack>
    </ScrollView>
  );
};

export default RecoverEmailClient;
