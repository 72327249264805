import React, {createContext, useContext, useEffect, useState} from 'react';
import {useRecoilState, useRecoilValue} from 'recoil';
import {loggedUser} from '@bri/shared-components';
import SocketService from '../services/SocketService';
import {countNewNotificationsRecoil} from '../recoil/atoms/countNewNotifications';

type Props = {
  children: React.ReactNode;
};

type SocketContextType = {
  init: () => void;
  finish: () => void;
};

export const SocketContext = createContext<SocketContextType | undefined>(undefined);

export function useSocket() {
  return useContext(SocketContext);
}

export const SocketProvider: React.FC<Props> = ({children}) => {
  const [, setNewNotifications] = useRecoilState(countNewNotificationsRecoil);
  const user = useRecoilValue(loggedUser);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const signalNewNotification = () => {
    setNewNotifications(prevNotifications => prevNotifications + 1);
  };

  const socketService = new SocketService(signalNewNotification);

  if (!isLoaded && user) {
    setIsLoaded(true);
    socketService.init();
  }

  return <SocketContext.Provider value={socketService}>{children}</SocketContext.Provider>;
};
