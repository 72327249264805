import {Box, Center, Column, Icon, Image, Row, Text, View} from 'native-base';
import React, {FC} from 'react';
import {AntDesign} from '@native-base/icons';
import {useTranslation} from 'react-i18next';
import {useNavigation} from '@react-navigation/native';
import {TouchableOpacity} from 'react-native-gesture-handler';
import {getResource, Images} from '../utils/imagesUtils';
import {getMultilanguageTranslation} from '../utils/languageUtils';

export type ItemInfo = {
  id?: string;
  name?: string;
  expiration_date?: Date;
  code?: string;
  image?: string;
  description?: string;
  type?: string;
  promotionCode?: string;
  client?: string;
};

export enum ItemType {
  Product = 'PRODUCT',
  Promotion = 'PROMOTION',
}

type Props = {
  data: ItemInfo;
};

export const Item: FC<Props> = props => {
  const navigation = useNavigation<any>();
  const {t} = useTranslation();
  let exp_date: Date;
  if (props?.data?.expiration_date) {
    exp_date = new Date(props?.data?.expiration_date);
  }

  const image = props?.data?.image ? props?.data?.image : Images.product;

  function itemContent() {
    return (
      <>
        <Row>
          <Image width={147} height={88} source={getResource(image)} resizeMode="cover" />
          <Column px={4} justifyContent="center" flexShrink={1} flexGrow={1} overflow="hidden">
            <Text bold noOfLines={2}>
              {getMultilanguageTranslation(props.data.name)}
            </Text>

            {props.data.code ? (
              <Text overflow="hidden" isTruncated>
                <Text overflow="hidden" isTruncated bold>
                  {t('Code')}:{' '}
                </Text>
                <Text overflow="hidden">{props.data?.code}</Text>
              </Text>
            ) : null}

            {props.data?.expiration_date ? (
              <Text numberOfLines={2} overflow="hidden" isTruncated>
                <Text overflow="hidden" isTruncated bold>
                  {t('Expiration')}:{' '}
                </Text>
                <Text overflow="hidden">{exp_date?.toLocaleDateString()}</Text>
              </Text>
            ) : null}
          </Column>
          {props.data.promotionCode && (
            <Center pr={2}>
              <Icon as={AntDesign} name="caretright" color="primary.500" />
            </Center>
          )}
        </Row>
      </>
    );
  }

  return (
    <Box rounded="lg" overflow="hidden" backgroundColor="secondary.500" mb={4}>
      {props.data?.type === ItemType.Promotion && !props.data.promotionCode ? (
        <View>{itemContent()}</View>
      ) : (
        <TouchableOpacity
          onPress={() => {
            if (props.data?.type === ItemType.Product) {
              navigation.navigate('Product', {id: props.data?.id});
            } else if (props.data?.type === ItemType.Promotion && props.data.promotionCode) {
              navigation.navigate('Promotion', {id: props.data?.id, additionalData: props.data});
            } else {
              navigation.navigate('Home');
            }
          }}>
          {itemContent()}
        </TouchableOpacity>
      )}
    </Box>
  );
};
