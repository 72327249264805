import {BottomTabScreenProps} from '@react-navigation/bottom-tabs';
import React, {FC, useState} from 'react';
import {Button, FormControl, Image, Input, ScrollView, Text, TextArea, View, VStack} from 'native-base';
import {useTranslation} from 'react-i18next';
import {TOAST_STATUS, TOAST_VARIANT, loggedUser, useSharedToast} from '@bri/shared-components';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {useRecoilState} from 'recoil';
import {ContactDTO} from '@bri/rotimpres-core';
import {ScreensParams} from '../navigators/screens';
import {getResource, Images} from '../utils/imagesUtils';
import {useContactService} from '../services/ContactService';

type Props = BottomTabScreenProps<ScreensParams, 'Contact'>;

export const ContactScreen: FC<Props> = props => {
  const {t} = useTranslation();
  const contactService = useContactService();
  const [user] = useRecoilState(loggedUser);
  const [isLoading, setIsLoading] = useState(false);
  const sharedToast = useSharedToast();

  const ContactSchema = Yup.object().shape({
    name: Yup.string().required(t('Required')!),
    email: Yup.string().email().required(t('Required')!),
    subject: Yup.string().required(t('Required')!),
    description: Yup.string().required(t('Required')!),
  });

  const {
    control,
    formState: {errors},
    handleSubmit,
  } = useForm<ContactDTO>({
    defaultValues: user ? {name: user.name, email: user.email} : {},
    resolver: yupResolver(ContactSchema),
  });

  const submitForm: SubmitHandler<ContactDTO> = data => {
    setIsLoading(true);

    contactService
      .submit(data)
      .response(() =>
        sharedToast({
          title: t('Form successfully submitted'),
          description: t('We will get back to you as soon as possible'),
          status: TOAST_STATUS.SUCCESS,
          variant: TOAST_VARIANT.SUBTLE,
        })
      )
      .error(() =>
        sharedToast({
          title: t('Something Went Wrong'),
          description: t('An unknown error occurred while submitting the form'),
          status: TOAST_STATUS.ERROR,
          variant: TOAST_VARIANT.SUBTLE,
        })
      )
      .finally(() => setIsLoading(false));
  };

  return (
    <ScrollView showsVerticalScrollIndicator={false} px={5} pb={5}>
      <View>
        <Image height={100} resizeMode="contain" source={getResource(Images.blueLogo)} mt={5} />

        <View px={2} mt={10}>
          <VStack space={2}>
            <FormControl isRequired isInvalid={'name' in errors} key="name">
              <FormControl.Label _text={{bold: true}} variant="contact">
                {t('Name')}
              </FormControl.Label>
              <Controller name="name" control={control} render={({field}) => <Input type="text" variant="contact" placeholder={t('Name')!} {...field} />} />
              {errors.name && <FormControl.ErrorMessage>{errors.name?.message}</FormControl.ErrorMessage>}
            </FormControl>

            <FormControl isRequired isInvalid={'email' in errors} key="email">
              <FormControl.Label _text={{bold: true}} variant="contact">
                {t('Email')}
              </FormControl.Label>
              <Controller
                name="email"
                control={control}
                render={({field}) => <Input type="text" keyboardType="email-address" variant="contact" placeholder={t('Email')!} {...field} />}
              />
              {errors.email && <FormControl.ErrorMessage>{errors.email?.message}</FormControl.ErrorMessage>}
            </FormControl>

            <FormControl isRequired isInvalid={'subject' in errors} key="subject">
              <FormControl.Label _text={{bold: true}} variant="contact">
                {t('Subject')}
              </FormControl.Label>
              <Controller name="subject" control={control} render={({field}) => <Input type="text" variant="contact" placeholder={t('Subject')!} {...field} />} />
              {errors.subject && <FormControl.ErrorMessage>{errors.subject?.message}</FormControl.ErrorMessage>}
            </FormControl>

            <FormControl isRequired isInvalid={'description' in errors} key="description" flex={1}>
              <FormControl.Label _text={{bold: true}} variant="contact">
                {t('Description')}
              </FormControl.Label>
              <Controller
                name="description"
                control={control}
                render={({field}) => (
                  <TextArea
                    autoCompleteType={false}
                    variant="contact"
                    placeholder={t('Description')!}
                    fontSize="sm"
                    minH={300}
                    borderWidth={2}
                    borderColor={'description' in errors ? 'error.600' : 'muted.300'}
                    {...field}
                  />
                )}
              />
              {errors.description && <FormControl.ErrorMessage>{errors.description?.message}</FormControl.ErrorMessage>}
            </FormControl>

            <Button
              onPress={handleSubmit(submitForm)}
              w="fit-content"
              shadow="2"
              style={{marginTop: 20}}
              variant="contact"
              alignSelf="end"
              px={8}
              isLoading={isLoading}
              isLoadingText={t('Submitting')!}>
              <Text color="white">{t('Send')}</Text>
            </Button>
          </VStack>
        </View>
      </View>
    </ScrollView>
  );
};
