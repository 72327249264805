import {BottomTabScreenProps} from '@react-navigation/bottom-tabs';
import React, {FC, useEffect, useState} from 'react';
import {Heading, ScrollView, Text, useTheme, View, VStack} from 'native-base';
import {useTranslation} from 'react-i18next';
import {useNotificationService} from '@bri/shared-components';
import {INotification, NOTIFICATION_TYPE, STATUS_TRANSFER} from '@bri/rotimpres-core';
import moment from 'moment';
import {useRecoilState} from 'recoil';
import {NotificationTarget} from '../components/NotificationTarget';
import {ScreensParams} from '../navigators/screens';
import {countNewNotificationsRecoil} from '../recoil/atoms/countNewNotifications';

type Props = BottomTabScreenProps<ScreensParams, 'Notification'>;

export const NotificationScreen: FC<Props> = () => {
  const {t} = useTranslation();
  const theme = useTheme();

  const currentDate = moment();
  const oneWeekAgo = moment().subtract(7, 'days');
  const oneMonthAgo = moment().subtract(1, 'months');

  const notificationsToday: INotification[] = [];
  const notificationsLastWeek: INotification[] = [];
  const notificationsLastMonth: INotification[] = [];
  const notificationsOlderThanOneMonth: INotification[] = [];

  const notificationService = useNotificationService();

  const [notifications, setNotifications] = useState<INotification[] | any>([]);

  const [newNotifications, setNewNotifications] = useRecoilState(countNewNotificationsRecoil);

  if (notifications) {
    notifications.forEach((notification: INotification) => {
      const notificationDate = moment(notification.created);
      if (notificationDate.isSameOrAfter(currentDate, 'day')) {
        notificationsToday.push(notification);
      } else if (notificationDate.isBetween(oneWeekAgo, currentDate)) {
        notificationsLastWeek.push(notification);
      } else if (notificationDate.isBetween(oneMonthAgo, currentDate)) {
        notificationsLastMonth.push(notification);
      } else {
        notificationsOlderThanOneMonth.push(notification);
      }
    });
    setNewNotifications(0);
  }

  useEffect(() => {
    notificationService
      .list({markAsRead: true})
      .response(async resp => {
        if (resp.notifications !== null) {
          setNotifications(resp.notifications);
        }
      })
      .error((err: any) => console.log(err));
  }, []);

  const hasNotifications = notifications.length !== 0;

  return (
    <ScrollView showsVerticalScrollIndicator={false} px={5} pb={5}>
      {hasNotifications ? (
        <VStack px={2} mt={10}>
          {[
            {title: t('Today'), data: notificationsToday},
            {title: t('Last Week'), data: notificationsLastWeek},
            {title: t('Last Month'), data: notificationsLastMonth},
            {title: t('Older'), data: notificationsOlderThanOneMonth},
          ].map((section, sectionIndex) =>
            section.data.length > 0 ? (
              <View key={sectionIndex}>
                <Text fontSize="xl" fontWeight="bold" color={theme.colors.primary[500]} paddingTop={sectionIndex === 0 ? undefined : 4}>
                  {section.title}
                </Text>
                {section.data.map((notification, notificationIndex) => (
                  <View key={notificationIndex} pb={2}>
                    <NotificationTarget
                      key={notificationIndex}
                      description={notification.description}
                      date={new Date(notification.created)}
                      button={
                        notification.data !== STATUS_TRANSFER.REJECTED && notification.data !== STATUS_TRANSFER.APPROVED && notification.type === NOTIFICATION_TYPE.TRANSFER
                          ? 'Details'
                          : null
                      }
                      transferId={
                        notification.data !== STATUS_TRANSFER.REJECTED && notification.data !== STATUS_TRANSFER.APPROVED && notification.type === NOTIFICATION_TYPE.TRANSFER
                          ? notification.data.toString()
                          : null
                      }
                    />
                  </View>
                ))}
              </View>
            ) : null
          )}
        </VStack>
      ) : (
        <Heading color="primary.500" size="lg" fontSize="lg" bold paddingTop="45px" textAlign="center">
          {t('You have not received any notification yet.')}
        </Heading>
      )}
    </ScrollView>
  );
};
