import {useRecoilState} from 'recoil';
import {loggedUser} from '@bri/shared-components';
import React, {useEffect, useState} from 'react';
import {IPromotion} from '@bri/rotimpres-core';
import {Heading, ScrollView, View} from 'native-base';
import {useTranslation} from 'react-i18next';
import {useProductsService} from '../services/ProductsService';
import {ItemList} from '../components/ItemList';
import {ItemType} from '../components/Item';

export const PromotionsListScreen = () => {
  const [user] = useRecoilState(loggedUser);
  const [loading, setLoading] = useState(true);
  const productsService = useProductsService();
  const {t} = useTranslation();

  const [promotionsActive, setPromotionsActive] = useState<IPromotion[]>([]);
  const [promotionsExpired, setPromotionsExpired] = useState<IPromotion[]>([]);

  const userID = user?._id;

  useEffect(() => {
    if (!userID) {
      setLoading(false);
      return;
    }

    productsService
      .listUserPromotions({owner: String(userID)})
      .response(async resp => {
        setPromotionsActive(resp.active);
        setPromotionsExpired(resp.expired);
      })
      .error(err => console.log(err))
      .finally(() => setLoading(false));
  }, [userID]);

  if (loading) return null;

  if ((!promotionsActive || promotionsActive.length <= 0) && (!promotionsExpired || promotionsExpired.length <= 0)) {
    return (
      <Heading color="primary.500" size="lg" fontSize="lg" bold
paddingTop="45px" textAlign="center">
        {t("There aren't any promotions available.")}
      </Heading>
    );
  }

  return (
    <ScrollView showsVerticalScrollIndicator={false}>
      <View mt={5}>
        {promotionsActive && promotionsActive.length > 0 && <ItemList title={t('Active Promotions') as string} data={promotionsActive as any} type={ItemType.Promotion} />}
        {promotionsExpired && promotionsExpired.length > 0 && <ItemList title={t('Expired Promotions') as string} data={promotionsExpired as any} type={ItemType.Promotion} />}
      </View>
    </ScrollView>
  );
};
