import React, {FC} from 'react';
import {FlatList, Heading} from 'native-base';
import {useTranslation} from 'react-i18next';
import CommonLayout from '../layouts/CommonLayout';
import {Item, ItemInfo, ItemType} from './Item';

type Props = {
  title?: string;
  data: ItemInfo[];
  type: ItemType;
};

export const ItemList: FC<Props> = props => {
  const [t] = useTranslation();
  return (
    <CommonLayout>
      <>
        <Heading color="primary.500" size="lg" fontSize="lg" bold paddingBottom={5}>
          {props.title}
        </Heading>
        <FlatList data={props.data} renderItem={({item, index}) => <Item data={{...item, type: props.type}} />} keyExtractor={index => index!.toString()} />
      </>
    </CommonLayout>
  );
};
