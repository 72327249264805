import {ContactServiceTypes} from '@bri/rotimpres-core';
import {useBaseService} from '@bri/shared-components';
import {ServiceFunctionsType} from '@bri/shared-core';

export function useContactService() {
  const newBaseService = useBaseService();

  const contactServiceFunctions: ServiceFunctionsType<ContactServiceTypes> = {
    submit: params => newBaseService().post('v1/contact/submit', params),
  };

  return contactServiceFunctions;
}
