import {BottomTabScreenProps} from '@react-navigation/bottom-tabs';
import React, {FC, useState} from 'react';
import {Button, Checkbox, FormControl, Icon, Image, Input, ScrollView, Select, Text, View, VStack} from 'native-base';
import {Trans, useTranslation} from 'react-i18next';
import InputMask from 'react-input-mask';
import {COOKIES_TYPES, CookiesService, CountrySelector, loggedUser, TOAST_STATUS, TOAST_VARIANT, useAuthService, useSharedToast} from '@bri/shared-components';
import {FontAwesome} from '@native-base/icons';
import {Controller, Message, SubmitHandler, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import moment from 'moment';
import {Link} from '@react-navigation/native';
import randomstring from 'randomstring';
import sha256 from 'sha256';
import {useSetRecoilState} from 'recoil';
import {AVAILABLE_LANGUAGES, AVAILABLE_LANGUAGES_TYPE, RegisterUserDTO, TAG_RESPONSE} from '@bri/rotimpres-core';
import {ScreensParams} from '../navigators/screens';
import {getResource, Images} from '../utils/imagesUtils';
import {useUsersService} from '../services/UsersService';
import {initialTagRecoil} from '../recoil/atoms/InitialTagAtom';
import usePushToken, {TOKEN_UPDATE_MODE} from '../utils/firebase/usePushToken';

type Props = BottomTabScreenProps<ScreensParams, 'Register'>;

type Inputs = {
  name: string;
  surname: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  phone: string;
  country: string;
  birthDate: string;
  language: AVAILABLE_LANGUAGES_TYPE;
  gender: string;
  policy: boolean;
  terms: boolean;
};

const today = new Date();
const minDate = moment().subtract(100, 'years');

function parseDateString(value: any, originalValue: any) {
  return moment(originalValue, 'DD/MM/YYYY').toDate();
}

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const RegisterScreen: FC<Props> = props => {
  const {t} = useTranslation();
  const userService = useUsersService();
  const authService = useAuthService();
  const setUser = useSetRecoilState(loggedUser);
  const setInitialTag = useSetRecoilState(initialTagRecoil);
  const [passwordInputType, setPasswordInputType] = useState<'password' | 'text' | undefined>('password');
  const [passwordConfirmationInputType, setPasswordConfirmationInputType] = useState<'password' | 'text' | undefined>('password');
  const [passwordIcon, setPasswordIcon] = useState('eye-slash');
  const [passwordConfirmationIcon, setPasswordConfirmationIcon] = useState('eye-slash');
  const sharedToast = useSharedToast();
  const [isLoading, setIsLoading] = useState(false);
  const {updatePushToken} = usePushToken();

  const RegisterSchema = Yup.object().shape({
    name: Yup.string().required(t('Required')!),
    surname: Yup.string().required(t('Required')!),
    email: Yup.string()
      .email()
      .required(t('Required') as Message),
    password: Yup.string().min(8).required(t('Required')!),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref('password'), undefined], t('Passwords must match')!)
      .required(t('Required')!),
    phone: Yup.string()
      .test('telephone-min-length', t('Phone number must have exactly 9 digits')!, value => {
        if (!value) return true;
        if (value.length === 0) return true;
        return value.length === 9;
      })
      .optional(),
    country: Yup.string().required(t('Required')!),
    birthDate: Yup.date()
      .typeError(t('Invalid format date')!)
      .transform(parseDateString)
      .max(today, t("Birthdate can't be future")!)
      .min(minDate, t("Birthdate can't be older than 100 years")!)
      .required(t('Required')!),
    language: Yup.string().optional(),
    gender: Yup.string().optional(),
    policy: Yup.boolean().oneOf([true], t('Privacy policy is required')!),
    terms: Yup.boolean().oneOf([true], t('Terms is required')!),
  });

  const {
    control,
    formState: {errors},
    handleSubmit,
    setValue,
  } = useForm<Inputs>({
    defaultValues: {policy: false, terms: false},
    resolver: yupResolver(RegisterSchema),
  });

  function tagSharedToast(tagResponse?: TAG_RESPONSE) {
    if (tagResponse !== undefined && tagResponse !== TAG_RESPONSE.MINE) {
      if (tagResponse === TAG_RESPONSE.CLAIM) {
        sharedToast({
          title: t('Tag claimed!'),
          description: t(''),
          status: TOAST_STATUS.SUCCESS,
          variant: TOAST_VARIANT.SUBTLE,
        });
      } else if (tagResponse === TAG_RESPONSE.TRANSFER) {
        sharedToast({
          title: t('Petition successfully submitted'),
          description: t('Wait for a reply'),
          status: TOAST_STATUS.SUCCESS,
          variant: TOAST_VARIANT.SUBTLE,
        });
      } else if (tagResponse === TAG_RESPONSE.TRANSFER_WAIT) {
        sharedToast({
          title: t('Wait'),
          description: t(`Wait a time before making a request again`),
          status: TOAST_STATUS.ERROR,
          variant: TOAST_VARIANT.SUBTLE,
        });
      }
    }
  }

  const submitForm: SubmitHandler<Inputs> = data => {
    setIsLoading(true);

    const codeVerifier = randomstring.generate();
    const state = randomstring.generate();
    const codeChallenge = sha256(codeVerifier);

    const user: RegisterUserDTO = {
      channels: [],
      categories: [],
      ...data,
      birthDate: moment(data.birthDate).format('DD/MM/YYYY'),
      code_challenge_method: 'S256',
      redirect_uri: 'localhost',
      phone: data.phone?.length === 0 ? undefined : data.phone,
      state,
      code_challenge: codeChallenge,
      tagId: props.route.params ? props.route.params.tagId : undefined, // props.route.params.tagId || undefined,
    };
    userService
      .register(user)
      .response(registerResp => {
        if (registerResp.authCode && registerResp.authState === state) {
          authService
            .token({
              grant_type: 'authorization_code',
              redirect_uri: 'localhost',
              code: registerResp.authCode,
              code_verifier: codeVerifier,
            })
            .response(async tokenResp => {
              await CookiesService.setType(COOKIES_TYPES.TECHNICAL, 'access_token', tokenResp.access_token);
              await CookiesService.setType(COOKIES_TYPES.TECHNICAL, 'refresh_token', tokenResp.refresh_token);
              if (props.route.params) {
                setInitialTag(props.route.params.tagId);
              } else {
                setInitialTag(null);
              }
              setUser(registerResp.user);
              tagSharedToast(registerResp.tagResponse);
              await updatePushToken(TOKEN_UPDATE_MODE.ENTER, registerResp.user);
            })
            .error(err => {
              setIsLoading(false);
              sharedToast({
                title: t('Something Went Wrong'),
                description: err.type as string,
                status: TOAST_STATUS.ERROR,
                variant: TOAST_VARIANT.SUBTLE,
              });
            });
        } else {
          setIsLoading(false);
          sharedToast({
            title: t('Something Went Wrong'),
            description: t('Server Error [OAUTH STATE]'),
            status: TOAST_STATUS.ERROR,
            variant: TOAST_VARIANT.SUBTLE,
          });
        }
      })
      .error(err => {
        if (err.type === 'ALREADY_EXISTS') {
          sharedToast({
            title: t('Already Exist'),
            description: t('User with that email is already registered.'),
            status: TOAST_STATUS.ERROR,
            variant: TOAST_VARIANT.SUBTLE,
          });
        } else {
          sharedToast({
            title: t('Something Went Wrong'),
            description: err.type as string,
            status: TOAST_STATUS.ERROR,
            variant: TOAST_VARIANT.SUBTLE,
          });
        }
      })
      .finally(() => setIsLoading(false));
  };

  const handlePassword = () => {
    if (passwordInputType === 'text') {
      setPasswordInputType('password');
      setPasswordIcon('eye');
    } else {
      setPasswordInputType('text');
      setPasswordIcon('eye-slash');
    }
  };

  const handlePasswordConfirmation = () => {
    if (passwordConfirmationInputType === 'text') {
      setPasswordConfirmationInputType('password');
      setPasswordConfirmationIcon('eye');
    } else {
      setPasswordConfirmationInputType('text');
      setPasswordConfirmationIcon('eye-slash');
    }
  };

  return (
    <ScrollView showsVerticalScrollIndicator={false} bgColor="primary.500" px={5} pb={5}>
      <View>
        <Image height={100} resizeMode="contain" source={getResource(Images.header_logo)} />
        <Text alignSelf="center" color="white" textAlign="center" my={4}>
          {t('Scan the NFC, collect your tokens and get fantastic promotions and exclusive offers. Complete the registration to get your promotion.')}
        </Text>
        <View px={2}>
          <VStack space={4}>
            <FormControl isRequired isInvalid={'name' in errors} key="name" flex={1}>
              <FormControl.Label _text={{bold: true}} variant="register">
                {t('Name')}
              </FormControl.Label>
              <Controller name="name" control={control} render={({field}) => <Input type="text" variant="register" placeholder={t('Name')!} {...field} />} />
              {errors.name && <FormControl.ErrorMessage>{errors.name?.message}</FormControl.ErrorMessage>}
            </FormControl>

            <FormControl isRequired isInvalid={'surname' in errors} key="surname" flex={1}>
              <FormControl.Label _text={{bold: true}} variant="register">
                {t('Surname')}
              </FormControl.Label>
              <Controller name="surname" control={control} render={({field}) => <Input type="text" variant="register" placeholder={t('Surname')!} {...field} />} />
              {errors.surname && <FormControl.ErrorMessage>{errors.surname?.message}</FormControl.ErrorMessage>}
            </FormControl>

            <FormControl isRequired isInvalid={'email' in errors} key="email" flex={1}>
              <FormControl.Label _text={{bold: true}} variant="register">
                {t('Email')}
              </FormControl.Label>
              <Controller
                name="email"
                control={control}
                render={({field}) => <Input type="text" keyboardType="email-address" variant="register" placeholder={t('Email')!} {...field} />}
              />
              {errors.email && <FormControl.ErrorMessage>{errors.email?.message}</FormControl.ErrorMessage>}
            </FormControl>

            <FormControl isRequired isInvalid={'password' in errors} key="password" flex={1}>
              <FormControl.Label _text={{bold: true}} variant="register">
                {t('Password')}
              </FormControl.Label>
              <Controller
                name="password"
                control={control}
                render={({field}) => (
                  <Input
                    type={passwordInputType}
                    variant="register"
                    InputRightElement={
                      <Icon
                        as={FontAwesome}
                        name={passwordIcon}
                        size={5}
                        mr={2}
                        color="muted.50"
                        onPress={() => {
                          handlePassword();
                        }}
                      />
                    }
                    pl={2}
                    {...field}
                  />
                )}
              />
              {errors.password && <FormControl.ErrorMessage>{errors.password?.message}</FormControl.ErrorMessage>}
            </FormControl>

            <FormControl isRequired isInvalid={'passwordConfirmation' in errors} key="passwordConfirmation" flex={1}>
              <FormControl.Label _text={{bold: true}} variant="register">
                {t('Repeat Password')}
              </FormControl.Label>
              <Controller
                name="passwordConfirmation"
                control={control}
                render={({field}) => (
                  <Input
                    type={passwordConfirmationInputType}
                    variant="register"
                    InputRightElement={
                      <Icon
                        as={FontAwesome}
                        name={passwordConfirmationIcon}
                        size={5}
                        mr={2}
                        color="muted.50"
                        onPress={() => {
                          handlePasswordConfirmation();
                        }}
                      />
                    }
                    {...field}
                  />
                )}
              />
              {errors.passwordConfirmation && <FormControl.ErrorMessage>{errors.passwordConfirmation.message}</FormControl.ErrorMessage>}
            </FormControl>

            <FormControl isInvalid={'phone' in errors} key="phone" flex={1}>
              <FormControl.Label _text={{bold: true}} variant="register">
                {t('Phone')}
              </FormControl.Label>
              <Controller
                name="phone"
                control={control}
                render={({field}) => <Input type="text" keyboardType="phone-pad" variant="register" placeholder={t('Phone')!} {...field} />}
              />
              {errors.phone && <FormControl.ErrorMessage>{errors.phone?.message}</FormControl.ErrorMessage>}
            </FormControl>

            <FormControl isRequired isInvalid={'country' in errors} key="country" flex={1}>
              <FormControl.Label _text={{bold: true}} variant="register">
                {t('Country')}
              </FormControl.Label>
              <Controller
                name="country"
                control={control}
                render={({field}) => <CountrySelector {...(field as any)} onValueChange={(itemValue: string) => setValue('country', itemValue)} firstCountry="ES" />}
              />
              {errors.country && <FormControl.ErrorMessage>{errors.country.message}</FormControl.ErrorMessage>}
            </FormControl>

            <FormControl isRequired isInvalid={'birthDate' in errors} key="birthDate" flex={1}>
              <FormControl.Label _text={{bold: true}} variant="register">
                {t('Birth date')}
              </FormControl.Label>
              <Controller
                name="birthDate"
                control={control}
                render={({field}) => (
                  <InputMask {...field} mask="99/99/9999">
                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                    {/* @ts-ignore */}
                    {(inputProps: any) => <Input type="text" variant="register" placeholder={t('dd/mm/yyyy')!} {...field} />}
                  </InputMask>
                )}
              />
              {errors.birthDate && <FormControl.ErrorMessage>{errors.birthDate.message}</FormControl.ErrorMessage>}
            </FormControl>

            <FormControl isInvalid={'gender' in errors} key="gender" flex={1}>
              <FormControl.Label _text={{bold: true}} variant="register">
                {t('Gender')}
              </FormControl.Label>
              <Controller
                name="gender"
                control={control}
                render={({field}) => (
                  <Select {...(field as any)} onValueChange={itemValue => setValue('gender', itemValue)}>
                    {[
                      {title: t('Male'), value: 'm'},
                      {title: t('Female'), value: 'f'},
                      {title: t('No binary'), value: 'nb'},
                      {title: t('Other'), value: 'other'},
                      {title: t('I prefer not to answer'), value: 'nc'},
                    ].map(item => (
                      <Select.Item key={item.value} label={item.title} value={item.value} variant="register" />
                    ))}
                  </Select>
                )}
              />
              {errors.gender && <FormControl.ErrorMessage>{errors.gender.message}</FormControl.ErrorMessage>}
            </FormControl>

            <FormControl isInvalid={'language' in errors} key="language" flex={1}>
              <FormControl.Label _text={{bold: true}} variant="register">
                {t('Language')}
              </FormControl.Label>
              <Controller
                name="language"
                control={control}
                render={({field}) => (
                  <Select {...(field as any)} onValueChange={(itemValue: AVAILABLE_LANGUAGES_TYPE) => setValue('language', itemValue)}>
                    {AVAILABLE_LANGUAGES.map(item => (
                      <Select.Item key={item} label={t(`lang_${item}`)} value={item} variant="register" />
                    ))}
                  </Select>
                )}
              />
              {errors.language && <FormControl.ErrorMessage>{errors.language.message}</FormControl.ErrorMessage>}
            </FormControl>

            <VStack px={5} space={4} mt={4}>
              <FormControl isRequired isInvalid={'policy' in errors} key="policy">
                <Controller
                  name="policy"
                  control={control}
                  render={({field}) => (
                    <Checkbox {...(field as any)} variant="register" _text={{fontSize: 14}} size="sm">
                      <Trans>
                        <Text color="muted.50">
                          {t('I have read and accept the _privacy policy_')}
                          <Link to={{screen: 'PrivacyPolicy'}} style={{borderBottomWidth: 1, borderBottomColor: 'white'}}>
                            {t('privacy policy')}
                          </Link>
                          .
                        </Text>
                      </Trans>
                    </Checkbox>
                  )}
                />
                {errors.policy && <FormControl.ErrorMessage>{errors.policy.message}</FormControl.ErrorMessage>}
              </FormControl>

              <FormControl isRequired isInvalid={'terms' in errors} key="terms">
                <Controller
                  name="terms"
                  control={control}
                  render={({field}) => (
                    <Checkbox {...(field as any)} variant="register" _text={{fontSize: 14}} size="sm">
                      <Trans>
                        <Text color="muted.50">
                          {t('I have read and accept the _terms of use_')}
                          <Link to={{screen: 'Terms'}} style={{borderBottomWidth: 1, borderBottomColor: 'white'}}>
                            {t('terms of use')}
                          </Link>
                          .
                        </Text>
                      </Trans>
                    </Checkbox>
                  )}
                />
                {errors.terms && <FormControl.ErrorMessage>{errors.terms.message}</FormControl.ErrorMessage>}
              </FormControl>
            </VStack>

            <Button
              onPress={handleSubmit(submitForm)}
              w="fit-content"
              shadow="2"
              style={{marginTop: 20}}
              alignSelf="center"
              px={8}
              bgColor="secondary.500"
              _text={{color: 'primary.500'}}
              isLoading={isLoading}
              isLoadingText={t('Submitting')!}>
              {t('Register')}
            </Button>
          </VStack>
        </View>
      </View>
    </ScrollView>
  );
};
