import {Image} from 'react-native';

export type ImageResource = {
  uri: string;
};

const getResource = (required: number | string): ImageResource => {
  if (typeof required === 'number') {
    return Image.resolveAssetSource(required);
  }
  return {uri: required};
};

const getResourceUri = (required: number | string): string => {
  if (typeof required === 'number') {
    return Image.resolveAssetSource(required).uri;
  }
  return required;
};

const Images = {
  background: require('../assets/background.png'),
  blueLogo: require('../assets/logo_blue.png'),
  header_logo: require('../assets/header_logo.png'),
  title: require('../assets/title.svg'),
  product: require('../assets/productStock.png'),
  nfc_ok: require('../assets/nfc_ok.png'),
  nfc_rejected: require('../assets/nfc_rejected.png'),
  arrowUp: require('../assets/arrowUp.svg'),
  arrowDown: require('../assets/arrowDown.svg'),
  logo_footer: require('../images/rot_footer_logo.png'),
  logo: require('../images/rot_logo.png'),
};

enum PathsSVG {
  mapIcon = 'M18 4H0V0H18V4ZM6.5 8H11.5C11.78 8 12 8.22 12 8.5V9.82C13.2054 8.64997 14.8201 7.99692 16.5 8C16.67 8 16.84 8 17 8.03V5H1V18H11.03C10.3554 16.9575 9.99763 15.7417 10 14.5C10 12.75 10.69 11.17 11.82 10H6V8.5C6 8.22 6.22 8 6.5 8ZM16 10.5V9L13.75 11.25L16 13.5V12C16.663 12 17.2989 12.2634 17.7678 12.7322C18.2366 13.2011 18.5 13.837 18.5 14.5C18.5 14.9 18.41 15.28 18.24 15.62L19.33 16.71C19.75 16.08 20 15.32 20 14.5C20 12.29 18.21 10.5 16 10.5ZM16 17C15.337 17 14.7011 16.7366 14.2322 16.2678C13.7634 15.7989 13.5 15.163 13.5 14.5C13.5 14.1 13.59 13.72 13.76 13.38L12.67 12.29C12.25 12.92 12 13.68 12 14.5C12 16.71 13.79 18.5 16 18.5V20L18.25 17.75L16 15.5V17Z',
  tokenIcon = 'M2.89,250.37C2.72,112.3,111.01,2.48,247.77,2.05c138.46-.44,249.71,110.46,249.23,248.47-.47,134.31-111.15,244.38-246.01,244.64C112.7,495.43,3.07,387.26,2.89,250.37Zm46.54-4.31c-.93,111.88,87.31,202.31,198.12,203.02,110.38,.71,201.93-89.29,202.91-199.5,.96-107.7-89.5-199.78-197.68-201.21-111.54-1.48-202.43,86.88-203.35,197.69Z M369.23,249.51c-4.07,9.69-5.81,20.91-12.2,27.84-24.75,26.85-49.74,53.73-77.06,77.86-19.31,17.05-43.31,15.93-62.6-1.41-25.48-22.89-50.14-46.96-72.9-72.52-19.53-21.94-17.39-46.62,2.44-67.9,21.81-23.4,42.66-49.01,68.68-66.65,14.75-10,45.63-15.1,57.78-6.41,32.05,22.95,58.3,54.2,85.76,83.18,5.66,5.98,6.58,16.45,10.11,26Zm-186.3-5.57c24.35,26.75,45.53,50,64.49,70.83,24.59-22.51,47.85-43.81,71.2-65.18-24.11-25.05-46.02-47.82-69.03-71.73-24.41,24.2-46.81,46.41-66.65,66.08Z',
  perfilIcon = 'M487,249.05c.03,137.78-104.37,246.21-236.78,245.93-131.13-.27-235.36-108.8-235.25-244.97C15.07,113.24,118.64,4.47,249.23,3.98c132.48-.49,237.75,108,237.77,245.07Zm-84.63,117.42c62.65-67.96,50.56-194.65-25.39-266.04C303.33,31.21,187.4,34.91,116.03,108.77c-67.97,70.35-75.91,198.56-15.92,257.12,86.88-72.92,222.91-67.62,302.25,.59Z M333.05,187.15c.27,47.63-37.06,86.69-82.49,86.31-44.48-.37-81.42-38.62-81.89-84.8-.48-47.61,36.59-86.02,82.71-85.7,45.38,.31,81.41,37.45,81.67,84.19Z',
  promotionsIcon = 'M249.38,2.59c132.47,.78,239.14,110.55,238.62,245.55-.52,135.92-109.6,245.42-243.57,244.53C111.65,491.78,4.49,379.74,5.78,243.15,7.05,108.83,115.43,1.8,249.38,2.59Zm-1.3,46.45c-108.17-.73-195.62,86.33-196.2,195.33-.59,111.52,85.27,200.83,193.72,201.47,107.49,.63,195.07-86.8,196.23-195.9,1.19-111.48-84.33-200.15-193.74-200.89Z M158.5,365.23c-9.53-8.95-19.65-18.46-29.85-28.05,70.93-68.11,139.53-133.97,209.96-201.6,30.33,17.33,31.63,23.42,1.58,52.86-59.52,58.32-119.42,116.24-181.69,176.78Z M366.58,325.73c.57,23.31-18.12,42.86-41.28,43.19-21.48,.3-40.14-17.65-41.27-39.7-1.21-23.6,16.55-43.89,39.35-44.95,22.34-1.04,42.63,18.43,43.19,41.47Z M210.46,167.48c.53,24.68-16.4,42.45-40.55,42.57-22.25,.11-40.8-17.11-41.94-38.93-1.15-22.02,18.21-43.82,39.98-45.03,22.55-1.25,42,17.69,42.51,41.38Z',
}

export {Images, PathsSVG, getResource, getResourceUri};
