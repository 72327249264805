import {MultilanguageString, AVAILABLE_LANGUAGES_TYPE, AVAILABLE_LANGUAGES, DEFAULT_LANGUAGE, MultilanguageStringGeneric} from '@bri/rotimpres-core';
import i18n from '../../i18n';

// When lang not specified, takes the user language (or the default language in case it's not available)
export function getMultilanguageTranslation(multilanguageString?: MultilanguageString | MultilanguageStringGeneric | string, lang?: AVAILABLE_LANGUAGES_TYPE | string) {
  if (!multilanguageString) return '';

  if (typeof multilanguageString == 'string') return multilanguageString as string;

  const mls = multilanguageString as MultilanguageStringGeneric;

  return mls[lang ?? getCurrentLanguage()] ?? mls[DEFAULT_LANGUAGE] ?? '';
}

export function getCurrentLanguage(): AVAILABLE_LANGUAGES_TYPE {
  if (String(AVAILABLE_LANGUAGES).includes(i18n.language)) {
    return i18n.language as AVAILABLE_LANGUAGES_TYPE;
  }

  return DEFAULT_LANGUAGE;
}

export const changeLanguage = (lng: AVAILABLE_LANGUAGES_TYPE | string | undefined) => {
  if (!lng) return;
  i18n.changeLanguage(lng);
};
