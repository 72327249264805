import * as React from 'react';
import {TouchableOpacity, ViewStyle} from 'react-native';
import Svg, {Circle, Path, Rect} from 'react-native-svg';

type Props = {
  path: string;
  color?: string;
  style?: ViewStyle;
};

export default function SvgIcons(props: Props) {
  const {path, color, style} = props;

  return (
    <Svg height="100%" width="100%" viewBox="0 0 500 500" style={style}>
      <Path d={path} fill={color} />
    </Svg>
  );
}
