import {ICertificate} from '@bri/rotimpres-core';
import {Box, Collapse, Text} from 'native-base';
import React from 'react';

export function CertificatesDropdown({certificates, open}: {certificates: ICertificate[]; open: boolean}): JSX.Element {
  const handleCertificateClick = (certificate: ICertificate) => {
    openLink(certificate.info);
  };

  return (
    <>
      <Collapse isOpen={open}>
        <Box p={4}>
          {certificates.map((certificate: ICertificate, index: any) => (
            <Text
              marginTop={1.5}
              color="lightBlue.900"
              underline
              w="fit-content"
              textAlign="left"
              background="white"
              key={`certificate-${index}`}
              onPress={() => handleCertificateClick(certificate)}>
              <Text mb={1}>{certificate.name}</Text>
            </Text>
          ))}
        </Box>
      </Collapse>
    </>
  );
}

function openLink(url?: string) {
  window.open(url, '_blank');
}
