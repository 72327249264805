import React from 'react';
import {Button, Image, ScrollView, Text, VStack} from 'native-base';
import {useTranslation} from 'react-i18next';
import {useNavigation} from '@react-navigation/native';
import {ImageBackground} from 'react-native';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import logo from '../images/logo_home.png';

export const HomeScreen = () => {
  const {t} = useTranslation();
  const navigation = useNavigation<any>();

  return (
    <ImageBackground source={require('../assets/rot_background.png')} style={{flex: 1}}>
      <ScrollView flex={1} contentContainerStyle={{flex: 1, justifyContent: 'center'}} showsVerticalScrollIndicator={false} backgroundColor="rgba(0, 89, 167, 0.7)">
        <VStack space={4} px={10} py={8} borderRadius={20}>
          <VStack alignItems="center">
            <Image source={logo} alt={t('Icon text').toString()} size="lg" width="340px" height="330px" />
            <Text color="white" textAlign="center" paddingBottom="55px" fontSize="16px" fontWeight={700} lineHeight="20px">
              {t('Scan the NFC, collect your tokens and get fantastic promotions and exclusive offers')}
            </Text>

            <Button
              alignItems="right"
              variant="login"
              color="primary.500"
              borderWidth={2}
              borderColor="secondary.500"
              width={200}
              onPress={() => {
                navigation.navigate('Login');
              }}
              mt={5}
              size="lg"
              paddingRight={2}>
              <Text color="secondary.500" fontSize={15}>
                {t('Sign In')}{' '}
              </Text>
            </Button>

            <Button
              alignItems="right"
              variant="login"
              color="primary.500"
              borderWidth={2}
              borderColor="secondary.500"
              width={200}
              onPress={() => {
                navigation.navigate('Register');
              }}
              mt={5}
              size="lg"
              paddingRight={2}>
              <Text color="secondary.500" fontSize={15}>
                {t('Register')}{' '}
              </Text>
            </Button>
          </VStack>
        </VStack>
      </ScrollView>
    </ImageBackground>
  );
};
