export const getLinkingSchema = (isSignedIn: boolean | undefined) => {
  const linking: any = {
    screens: {
      Home: {
        path: 'Home',
      },
      Register: {
        path: 'Register',
      },
      Login: {
        path: 'Login',
      },
      RecoverEmailClient: {
        path: 'RecoverEmail',
      },
      ChangePasswordClient: {
        path: 'ChangePassword',
      },
      Terms: {
        path: 'Terms',
      },
      PrivacyPolicy: {
        path: 'PrivacyPolicy',
      },
      Cookies: {
        path: 'Cookies',
      },
      CookiesConsent: {
        path: 'CookiesConsent',
      },
      App: {
        path: '',
        screens: {
          ProductListScreen: {
            path: 'ProductListScreen',
            screens: {
              ProductListScreen: '',
            },
          },
          PromotionListScreen: {
            path: 'PromotionListScreen',
            screens: {
              PromotionListScreen: '',
            },
          },
          Product: '/Product/:id',
          Promotion: '/Promotion/:id',
          ProfileScreen: 'ProfileScreen',
          Notification: {path: 'Notification'},
          NotificationsSettings: {path: 'NotificationsSettings'},
          FAQ: {path: 'FAQ'},
        },
      },
      Validate: {
        path: '/t/:id',
      },
      PreviewProduct: {
        path: 'PreviewProduct/:id',
      },
      Contact: {
        path: 'Contact',
      },
    },
  };

  if (isSignedIn) {
    // Remove auth from linking config
    delete linking.screens.Home;
    delete linking.screens.Register;
    delete linking.screens.Login;
    delete linking.screens.RecoverEmailClient;
    delete linking.screens.ChangePasswordClient;
  } else {
    // Remove app from linking config
    delete linking.screens.App;
  }

  return linking;
};
