import {CommonActions, DarkTheme, DefaultTheme, NavigationContainer, useNavigationContainerRef} from '@react-navigation/native';
import React, {useEffect, useRef, useState} from 'react';
import {useColorMode} from 'native-base';
import {useReduxDevToolsExtension} from '@react-navigation/devtools';
import * as Linking from 'expo-linking';
import {Platform} from 'react-native';
import {COOKIES_TYPES, CookiesBanner, CookiesService, loggedUser, StorageService, TOAST_STATUS, TOAST_VARIANT, useAuthService, useSharedToast} from '@bri/shared-components';
import {useRecoilState, useSetRecoilState} from 'recoil';
import {useTranslation} from 'react-i18next';
import {WINDOW_WIDTH} from '../utils/mixins';
import StackNavigator from './StackNavigator';
import {getLinkingSchema} from './linking';
import {currentScreenNameRecoil} from '../recoil/atoms/CurrentScreenNameAtom';
import {changeLanguage} from '../utils/languageUtils';
import usePushToken, {TOKEN_UPDATE_MODE} from '../utils/firebase/usePushToken';

const PERSISTENCE_KEY = 'NAVIGATION_STATE_V1';

const Navigator = () => {
  const {t} = useTranslation();
  const [isReady, setIsReady] = React.useState(false);
  const [redirect, setRedirect] = React.useState<{redirect: string; params: {}}>();
  const [initialState, setInitialState] = React.useState();
  const navigatorRef = useNavigationContainerRef<any>();
  const routeNameRef = useRef<string>();
  const {colorMode} = useColorMode();
  const setUser = useSetRecoilState(loggedUser);
  const authService = useAuthService();
  const sharedToast = useSharedToast();
  const [isLoading, setIsLoading] = useState(true);
  const [user] = useRecoilState(loggedUser);
  const setCurrentScreenName = useSetRecoilState(currentScreenNameRecoil);
  const {updateServiceWorker} = usePushToken();

  const isAuthenticated = !!user;

  useReduxDevToolsExtension(navigatorRef);

  useEffect(() => {
    const loadUser = async () => {
      if ((await CookiesService.hasKey(COOKIES_TYPES.TECHNICAL, 'access_token')) && (await CookiesService.hasKey(COOKIES_TYPES.TECHNICAL, 'refresh_token'))) {
        authService
          .me()
          .response(resp => {
            setUser(resp.user);
            changeLanguage(resp.user.language);
          })
          .error(err => {
            sharedToast({
              title: t('Something Went Wrong'),
              description: err.type as string,
              status: TOAST_STATUS.ERROR,
              variant: TOAST_VARIANT.SUBTLE,
            });
          })
          .finally(() => setIsLoading(false));
      } else {
        setIsLoading(false);
      }
    };

    loadUser();
    updateServiceWorker();
  }, []);

  useEffect(() => {
    const restoreState = async () => {
      try {
        const initialUrl = await Linking.getInitialURL();

        if (Platform.OS !== 'web' && initialUrl == null) {
          // Only restore state if there's no deep link and we're not on web
          const savedStateString = await StorageService.getItem(PERSISTENCE_KEY);
          const state = savedStateString ? JSON.parse(savedStateString) : undefined;

          if (state !== undefined) {
            setInitialState(state);
          }
        }
      } finally {
        setIsReady(true);
      }
    };

    if (!isReady) {
      restoreState();
    }
  }, [isReady]);

  if (!isReady || isLoading) {
    // We haven't finished checking for the token yet
    // return <SplashScreen />;
    return null;
  }

  const linkingSchema = getLinkingSchema(isAuthenticated);

  return (
    <NavigationContainer
      ref={navigatorRef}
      theme={
        colorMode === 'dark'
          ? DarkTheme
          : {
              ...DefaultTheme,
              colors: {
                ...DefaultTheme.colors,
                background: 'white',
              },
            }
      }
      initialState={initialState}
      onReady={() => {
        routeNameRef.current = navigatorRef.getCurrentRoute()!.name;
      }}
      linking={{
        prefixes: ['##URL_PROJECTE##', Linking.createURL('')],
        config: linkingSchema,
      }}
      onUnhandledAction={action => {
        console.error('ERROR! Ruta no definida: ', action);
      }}
      onStateChange={async state => {
        Platform.OS !== 'web' && StorageService.setItem(PERSISTENCE_KEY, JSON.stringify(state));

        const previousRouteName = routeNameRef.current;
        const currentRouteName = navigatorRef.getCurrentRoute()!.name;
        const trackScreenView = (routeName: string) => {
          // Your implementation of analytics goes here!
          console.log('Current route name: ', routeName);
        };

        if (redirect) {
          // && currentRouteName !== SCREENS.App) {
          console.debug('Trying to go... ', redirect.redirect);
          navigatorRef.dispatch(CommonActions.navigate(redirect.redirect, redirect.params));
          setRedirect(undefined);
        }

        if (previousRouteName !== currentRouteName) {
          // Save the current route name for later comparison
          routeNameRef.current = currentRouteName;
          setCurrentScreenName(currentRouteName);

          // Replace the line below to add the tracker from a mobile analytics SDK
          await trackScreenView(currentRouteName);
        }
      }}>
      <StackNavigator />
      <CookiesBanner
        maxWidth={WINDOW_WIDTH}
        onAcceptPres={() => {
          navigatorRef.navigate('CookiesConsent');
        }}
      />
    </NavigationContainer>
  );
};

export default Navigator;
