import {NotificationsServiceTypes as NotificationsServiceTypesRC} from '@bri/rotimpres-core';
import {useBaseService} from '@bri/shared-components';
import {ServiceFunctionsType} from '@bri/shared-core';

export type NotificationsServiceTypes = Pick<NotificationsServiceTypesRC, 'registerToken'> &
  Pick<NotificationsServiceTypesRC, 'removeToken'> &
  Pick<NotificationsServiceTypesRC, 'removeAllTokens'>;

export function useNotificationsService() {
  const newBaseService = useBaseService();

  const notificationsServiceFunctions: ServiceFunctionsType<NotificationsServiceTypes> = {
    registerToken: params => newBaseService().auth().post('v1/notifications/registerToken', params),
    removeToken: params => newBaseService().auth().post('v1/notifications/removeToken', params),
    removeAllTokens: () => newBaseService().auth().post('v1/notifications/removeAllTokens'),
  };
  return notificationsServiceFunctions;
}
