import React from 'react';
import {CookiesConsent} from '@bri/shared-components';
import * as Update from 'expo-updates';
import CommonLayout from '../layouts/CommonLayout';

const CookiesConsentScreen = () => (
  <CommonLayout>
    <CookiesConsent
      onRemoveAllCookies={async () => {
        await Update.reloadAsync();
      }}
    />
  </CommonLayout>
);

export default CookiesConsentScreen;
